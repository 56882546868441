@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 200;
    src: url(../fonts/MaterialIcons-Regular.eot);
    src: local("Material Icons"), local("MaterialIcons-Regular"),
        url(../fonts/MaterialIcons-Regular.woff2) format("woff2"),
        url(../fonts/MaterialIcons-Regular.woff) format("woff"),
        url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 200,
        'GRAD' 0,
        'opsz' 48
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #444;
}

.header-logo img {
    width: 135px;
    /* width: 230px; */
}

.slider-images svg,
.slider-images svg * {
    fill: #103675 !important;
}

h4.heading-section {
    margin-bottom: 14px;
}

input,
select,
textarea {
    width: 100%;
    transition: 0.3s;
}

p {
    font-size: 16px;
    line-height: 1.6;
}

.slider-images .image-shape-01 {
    bottom: 190px;
    left: 220px;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo ul.main-menu li a img {
    width: 18px;
    opacity: 0.2;
    margin-right: 7px;
}

.header-logo ul.main-menu li a.active {
    background: #ffffff;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: 25px; */
    width: 290px;
    text-transform: capitalize;
    color: #333;
    letter-spacing: .4px;
    font-size: 10px;
    border: 1px solid rgb(51 51 51 / 11%);
    border-radius: 5px;
    font-weight: bold;
}

.header-logo ul.main-menu li {
    display: flex;
    align-items: center;
}

.header-logo ul.main-menu li ul.sub-menu li {
    display: block;
}

.header-logo ul.main-menu li ul.sub-menu li a {
    width: 100%;
    text-align: left;
    display: block;
    height: auto;
    padding: 6px 29px;
    border: 0;
    transition: auto;
    font-weight: bold;
}

.header-logo ul.main-menu span.menu-icon {
    position: absolute;
    right: 30px;
}

div#header .container {
    width: 100%;
    padding: 0 30px;
}

.header-logo ul.main-menu {
    margin-left: 30px;
}

.slider-wrapper {
    padding: 100px 15px 50px 15px;
}

.slider-wrapper {
    background: #405189;
    /* background: -moz-linear-gradient(125deg, #128db8 0%, #1a2e4f 60%, #0d2233 100%); */
    /* background: -webkit-linear-gradient(125deg, #128db8 0%, #1a2e4f 60%, #0d2233 100%); */
    /* background: -o-linear-gradient(125deg, #128db8 0%, #1a2e4f 60%, #0d2233 100%); */
    /* background: linear-gradient(125deg, #128db8 0%, #1a2e4f 60%, #0d2233 100%); */
    background-repeat: repeat;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
}

.slider-wrapper:after {
    content: "";
    background-image: url(https://matriyeacademy.com/website-assets//images/header-image-lines4k.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.btn-primary {
    font-weight: normal;
    letter-spacing: 1px;
}

span.pre-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
}

p.description {
    margin-bottom: 25px;
    font-size: 16px;
    color: #333;
}

h1.title.sal-animate {
    line-height: 1;
    margin: 10px 0 10px 0;
    font-size: 40px;
    font-weight: 800;
    padding-right: 150px;
    color: #00bcd4;
}

h1.title.sal-animate span {
    font-weight: 300;
    color: #FFF;
}

.content * {
    color: #fff;
}

.eduvibe-status.status-01 {
    color: #525fe1;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    background: #ffffff;
    display: inline-block;
    border-radius: 2px;
}

.eduvibe-status.status-01.bg-primary-color {
    color: #ffffff;
}

.edu-card.card-type-6 .inner .thumbnail .top-position.left-top {
    left: 10px;
    top: 10px;
}

.edu-card .thumbnail .top-position {
    position: absolute;
    z-index: 2;
}

.status-group {
    margin: -5px;
}

.banner-style-1 .banner-right-content .edu-card {
    min-width: 272px;
}

.edu-card.card-type-6 {
    background: #ffffff;
    box-shadow: 0px 10px 30px rgb(0 0 0 / 6%);
}

.edu-card.card-type-6 .inner {
    padding: 15px;
}

.edu-card.card-type-6 .inner .thumbnail {
    margin-bottom: 15px;
}

.edu-card .thumbnail {
    position: relative;
}

.edu-card.card-type-6 .inner .thumbnail a {
    height: 100%;
    display: block;
    border-radius: 3px;
    height: 130px;
}

.edu-card .thumbnail a {
    display: block;
    overflow: hidden;
}

.edu-card.card-type-6 .inner .thumbnail a img {
    height: 100%;
    border-radius: 3px;
}

.edu-card .thumbnail a img {
    transition: 0.4s;
    overflow: hidden;
    object-fit: cover;
}

.edu-meta.meta-04 {
    margin: -5px -6px;
}

.edu-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.edu-meta.meta-04 li {
    font-weight: 600;
    line-height: 22px;
    margin: 5px 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.edu-meta.meta-04 li i {
    color: #f86f03;
    margin-right: 8px;
    font-size: 16px;
}

.edu-card.card-type-6 .inner .content .title {
    margin-bottom: 12px;
    margin-top: 10px;
    font-size: 13px;
    line-height: 18px;
}

.edu-card.card-type-6 .inner .content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
}

.bg-primary-color {
    background: #e15252 !important;
}

.banner-style-1 .work-shop {
    min-width: 233px;
    max-width: 233px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0px 10px 25px rgb(0 0 0 / 5%);
    border-radius: 5px;
    margin: 20px 0 0 140px;
    position: relative;
    z-index: 1;
}

.banner-style-1 .work-shop .inner .thumbnail img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    outline: 3px solid #f2f2f2;
    border-radius: 50%;
}

.banner-style-1 .work-shop .inner .content .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0 0;
}

.banner-style-1 .work-shop .inner .content .time {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    margin-bottom: 20px;
}

a.edu-btn.btn-secondary,
button.edu-btn.btn-secondary {
    background: #3f51b5;
    color: #ffffff;
}

a.edu-btn.btn-small,
button.edu-btn.btn-small {
    height: 36px;
    line-height: 36px;
    padding: 0 13px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.banner-style-1 .eduvibe-hero-one-video .thumbnail {
    margin: 10px -59px 0 -57px;
}

.w-100 {
    width: 100% !important;
}


ul.banner-icon-list-items {
    margin-bottom: 10px;
}

ul.banner-icon-list-items li {
    color: #000;
    font-size: 16px;
    line-height: 2.3;
}

ul.banner-icon-list-items li i {
    margin-right: 5px;
    color: #8bc34a;
}

.hero-card img {
    box-shadow: 0 0 10px rgb(28 51 84 / 10%);
    margin-right: -15px;
}

.read-more-btn a {
    background: #4caf50;
}
  .hero-card span i {
      background: #0479a6;
      color: #ffffff;
      font-size: 10px;
      
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 4px solid #fff;
      text-align: center;
      line-height: 35px;
  }
  .card-bottom a.edu-btn {background: #3f51b5;text-align: center;} 
  .courses-tab .tab-menu li {padding: 0;}
  .tab-menu .nav li {margin: 0 5px;}
  .tab-menu .nav li button {background: #fff;padding: 4px 15px;}
  .tab-menu .nav li button:hover, .tab-menu .nav li button.active {background: #f2f2f2;}
  a.btn {border-radius: 0;}
  .header-cart .cart-btn img {width: 88px;}
  .single-cart-item .btn-close {
      font-size: 11px;
  }
  .section-title .title {font-size: 28px;}
  .bg-color-1 {background-color: #f2f2f2;}
  .featured-topics h3 {margin-bottom: 15px;font-size: 16px;}
  
  .featured-topics small {display: block;color: #000;font-size: 11px;text-decoration: none !important;font-weight: 500;}
  
  .featured-topics li {font-size: 13px;color: #b40f23;margin: 0 0 26px 0;font-weight: 600; }
  
  .featured-topics .section-title {margin-bottom: 50px;text-align: right;}
  .featured-topics h6 {
    font-weight: 500;
    color: #000;
    font-size: 16px;
  }
  
  .featured-topics .container h2 {text-align: left;} 
  .featured-topics .bg-wrapper {background: #f2f2f2;padding: 20px 30px;}
  li.select-lang a {width: 50px;background: #f2f2f2;text-align: center;height: 50px;display: flex;align-items: center;justify-content: center;}
  
  li.select-lang a i {font-size: 24px;}
  
  ul.main-menu {align-items: center;}
  
  
  /*** 
  
  ====================================================================
      Main Footer
  ====================================================================
  
  ***/

.main-footer {
    position: relative;

    background: #f8fbff;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    margin-bottom: -15px;
}

.main-footer .widgets-section {
    position: relative;
    padding: 40px;
}

.main-footer .circle-layer {
    position: absolute;
    right: -400px;
    bottom: -400px;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: #0063aa;
}

.main-footer .pattern-layer-two {
    position: absolute;
    left: 50px;
    bottom: 40px;
    width: 79px;
    height: 79px;
    background-position: center top;
    background-repeat: no-repeat;
}

.main-footer .pattern-layer-four {
    position: absolute;
    right: 140px;
    top: 40px;
    width: 69px;
    height: 69px;
    background-position: center top;
    background-repeat: no-repeat;
}

.main-footer .footer-widget {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
}

.main-footer .footer-column h5 {
    position: relative;
    font-weight: 700;
    color: #00184f;
    line-height: 1.2em;
    margin-bottom: 10px;
    margin-top: 30px;
    text-transform: capitalize;
}

.main-footer .logo-widget {
    position: relative;
}

.main-footer .logo-widget .logo {
    position: relative;
    margin-bottom: 15px;
}

.main-footer .logo-widget .info-list {
    position: relative;
    margin-bottom: 20px;
}

.main-footer .logo-widget .info-list li {
    position: relative;
    color: #00184f;
    font-size: 16px;
    margin-bottom: 6px;
}

.main-footer .logo-widget .info-list li a {
    position: relative;
    color: #00184f;
}

.social-box {
    position: relative;
}

.social-box li {
    position: relative;
    margin-right: 8px;
    display: inline-block;
}

.social-box li i {
    position: relative;
    color: #ffffff;
    font-size: 15px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50px;
    display: inline-block;
    background-color: #55acee;
}

.social-box li i::before {
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
    display: inline-block;
}

.social-box li i:hover::before {
    transform: rotateY(360deg);
}

.social-box li.twitter i {
    background-color: #55acee;
}

.pinterest {
    background-color: #bd081c;
    /* box-shadow: 0px 5px 10px rgb(253 29 29 / 50%); */
    color: white;
    border-radius: 50%;
    transition: 0.5s all;
}

.pinterest:hover {
    transform: translateY(-10px);
    transition: 0.5s all;
}

.pinterest i {
    background-color: transparent !important;
}

.quora {
    background: linear-gradient(135deg, #00d2ff, #3a7bd5);
    /* box-shadow: 0px 5px 10px rgb(0 210 255 / 50%); */
    color: white;
    border-radius: 50%;
    transition: 0.5s all;
}

.quora:hover {
    transform: translateY(-10px);
    transition: 0.5s all;
}

.quora i {
    background-color: transparent !important;
}

.social-box li.facebook i {
    background-color: #3b5999;
}

.social-box li.dribbble i {
    background-color: #ea4c89;
}

.main-footer .logo-widget .text {
    position: relative;
    color: #00184f;
    font-size: 17px;
    line-height: 1.2em;
    margin-top: 20px;
}

.main-footer .list {
    position: relative;
}

.main-footer .list li {
    position: relative;
    margin-bottom: 0;
}

.main-footer .list li a {
    position: relative;
    color: #00184f;
    font-size: 15px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-footer .list li a:hover {
    color: #103675;
}

.main-footer .lower-box {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 50px;
    padding: 0 40px 15px 40px;
}

/* Subscribe Form */

.subscribe-form {
    position: relative;
    width: 420px;
    margin-bottom: 20px;
}

.subscribe-form h6 {
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.subscribe-form .form-group {
    position: relative;
    display: block;
    z-index: 1;
    padding-right: 60px;
    margin-bottom: 0px;
}

.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="tel"],
.subscribe-form .form-group input[type="email"],
.subscribe-form .form-group textarea {
    position: relative;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    height: 40px;
    color: #00184f;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1px;
    border-radius: 6px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    background-color: #e8ebef;
    border: 0;
}



.subscribe-form .form-group input[type="submit"],
.subscribe-form .form-group button {
    position: absolute;
    width: 45px;
    height: 40px;
    right: 0px;
    top: 0px;
    bottom: 5px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #103675;
    display: inline-block;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    border: 0;
}

.subscribe-form .form-group button:hover {
    background-color: #103e88;
}

.main-footer .lower-box .text {
    position: relative;
    color: #00184f;
    font-size: 16px;
    line-height: 1.8em;
}

.main-footer .lower-box .singup {
    position: absolute;
    right: 15px;
    top: 0;
    font-weight: 400;
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    background: #b40f23;
    padding: 7px 28px;
}

.main-footer .footer-bottom {
    position: relative;
    padding: 25px 40px;
    border-top: 1px solid #dee0e4;
}

.main-footer .footer-bottom .copyright {
    position: relative;
    color: #102659;
    font-size: 16px;
}

.main-footer .footer-bottom .copyright a {
    position: relative;
    color: #102659;
}

.main-footer .footer-bottom .nav-column {
    position: relative;
}

.main-footer .footer-bottom .nav-column ul {
    position: relative;
    text-align: right;
}

.main-footer .footer-bottom .nav-column ul li {
    position: relative;
    margin-right: 12px;
    padding-right: 12px;
    line-height: 1.2em;
    display: inline-block;
    border-right: 1px solid #dee0e4;
}

.main-footer .footer-bottom .nav-column ul li a {
    position: relative;
    color: #102659;
    font-size: 15px;
}

.main-footer .footer-bottom .nav-column ul li:last-child {
    margin-right: 0px;
    padding-right: 0px;
    border-right: 0px;
}

.auto-container {
    position: static;
    max-width: 1366px;
    margin: 0 auto;
}

.call-to-action-content {
    max-width: 520px;
}

.call-to-action-content .btn:hover {
    background: #b40f23;
}

.course-hover {
    padding: 10px;
}

.courses-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.courses-btn .btn {
    width: 190px;
    font-weight: 400;
    font-size: 16px;
    padding: 0 0;
}

/*** 
  
  ====================================================================
      Banner Section Two
  ====================================================================
  
  ***/
.main-footer .logo-widget a img {
    width: 230px;
}

.call-to-action-wrapper {
    /* background-image: url(../../assets/images/male-female-office-workers.png); */
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: right center;
    padding: 80px 80px;
    border-radius: 0;
    position: relative;
    z-index: 1;
}

.call-to-action-content h4 {
    font-weight: 100;
    letter-spacing: 6px;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
}

.call-to-action-content .title {
    margin-bottom: 32px;
}

.call-to-action-content p {
    color: #ffffff8f;
    font-size: 16px;
    letter-spacing: 0.4px;
}

.call-to-action-content .btn {
    background: white;
}

.call-to-action-wrapper::after {
    width: 510px;
    height: 100%;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: #ffffff0f;
    border-bottom-right-radius: 100%;
    border-top-right-radius: 100%;
    z-index: -1;
}



/*box*/

.page-title-section {
    position:
        relative;
    padding-bottom: 130px;
    background-color: #f3f8fe;
    display: inline-block;
    width: 100%;
    padding-top: 60px;
}

.page-title-section .page-breadcrumb {
    position: relative;
    padding-top: 30px;
    margin-bottom: 90px;
    display: inline-block;
    border-bottom: 1px solid #06092d;
}

.page-title-section .page-breadcrumb li {
    position: relative;
    padding-right: 10px;
    margin-right: 10px;
    color: #78787c;
    font-size: 16px;
    display: inline-block;
}

.page-title-section .page-breadcrumb li:before {
    position: absolute;
    content: '-';
    right: -4px;
    top: 0px;
    font-size: 16px;
}

.page-title-section .page-breadcrumb li a {
    position: relative;
    color: #06092d;
}

.page-title-section .page-breadcrumb li:last-child {
    margin-right: 0px;
    padding-right: 0px;
}

.page-title-section .page-breadcrumb li:last-child::before {
    display: none;
}

.page-title-section .content-box {
    position: relative;
    text-align: center;
}

.page-title-section .content-box h2 {
    position: relative;
    color: #06142d;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2em;
}

.page-title-section .content-box .text {
    position: relative;
    color: #181818;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7em;
    margin-top: 18px;
}

.faq-blocks-section {
    position: relative;
    padding-bottom: 0;
    display: inline-block;
    width: 100%;
}

.faq-blocks-section .inner-container {
    position: relative;
    margin-top: -100px;
}

.faq-block {
    position: relative;
    margin-bottom: 30px;
}

.faq-block .inner-box {
    position: relative;
    text-align: center;
    padding: 20px;
    background-color: #eee1e0;
}

.faq-block:nth-child(2) .inner-box {
    background-color: #e9f5ef;
}

.faq-block:nth-child(2) .inner-box .icon-box {
    color: #43b97e;
}

.faq-block:nth-child(3) .inner-box {
    background-color: #f9f9e4;
}

.faq-block:nth-child(3) .inner-box .icon-box {
    color: #bdbd39;
}

.faq-block .inner-box .icon-box {
    position: relative;
    font-size: 64px;
    color: #c56b66;
    line-height: 1em;
}

.faq-block .inner-box h3 {
    position: relative;
    color: #06142d;
    font-weight: normal;
    font-size: 21px;
    text-transform: uppercase;
}

.faq-block .inner-box h3 a {
    position: relative;
    color: #103675;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.8px;
}

.faq-block .inner-box .text {
    position: relative;
    color: #06142d;
    font-size: 16px;
    line-height: 1.8em;
    margin: 12px 0px 22px;
    display: -webkit-box;
    height: 150px;
    max-width: 397px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text_faq {
    color: #06142d;
    font-size: 16px;
    line-height: 1.8em;
    margin: 5px 0;
}

.faq-block .inner-box .read-more {
    position: relative;
    color: #43b97e;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.faq-block .inner-box .read-more:hover {
    color: #111111;
}

.testimonial-content {
    width: 100%;
    padding: 0 20px;
}

.testimonial-outer {
    position: relative;
    background: #e9f2f5;
    padding: 30px 30px;
    margin-top: 70px;
    padding-top: 66px;
    height: 80%;
}

.testimonial-wrapper {
    background-color: #ffffff;
    padding: 0 40px 100px 40px;
}

.testimonial-wrapper p {
    font-size: 18px;
    max-width: 770px;
    margin: 0 auto 20px auto;
}

.testimonial-wrapper .item p {
    margin-bottom: 15px;
    font-size: 16px
}

.testimonial-wrapper .item {
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: center;
}

.testimonial-wrapper p {
    font-size: 18px;
    max-width: 770px;
    margin: 0 auto 20px auto;
}

.testimonial-wrapper .item p {
    margin-bottom: 15px;
    font-size: 16px;
}

.testimonial-wrapper .user-img {
    width: 80px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
    overflow: hidden;
    border-radius: 50%;
    height: 80px;
}

.testimonial-wrapper .user-img img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
}

.testimonial-wrapper .item {
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: center;
}

.testimonial-wrapper .item h5.name,
span.designation {
    width: 100%;
}

/**/


.contact-page-section {
    position: relative;
    padding: 110px 0px 70px;
}

.contact-page-section .pattern-layer-three {
    position: absolute;
    left: 10%;
    top: 55%;
    width: 45px;
    height: 43px;
    background-repeat: no-repeat;
}

.contact-page-section .info-column {
    position: relative;
    margin-bottom: 30px;
}

.contact-page-section .info-column .inner-column {
    position: relative;
}

.contact-page-section .info-column .title {
    position: relative;
    color: #43b97e;
    font-size: 16px;
    text-transform: uppercase;
}

.contact-page-section .info-column h2 {
    position: relative;
    color: #06142d;
    font-weight: 700;
    line-height: 1.2em;
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 22px;
}

.contact-page-section .info-column .text {
    position: relative;
    color: #181818;
    font-size: 18px;
    line-height: 1.7em;
    margin-bottom: 18px;
}

.contact-page-section .info-column ul {
    position: relative;
}

.contact-page-section .info-column ul li {
    position: relative;
    color: #06142d;
    font-size: 16px;
    line-height: 1.7em;
    margin-bottom: 17px;
}

.contact-page-section .info-column ul li span {
    position: relative;
    display: block;
    color: #fe6915;
    display: block;
    margin-bottom: 5px;
}

.contact-page-section .form-column {
    position: relative;
    margin-bottom: 30px;
    margin: 0 auto;
}

.contact-page-section .form-column .inner-column {
    position: relative;
    padding: 40px 35px;
    background-color: #f4f7fa;
}

.contact-page-section .form-column .circle-layer {
    position: absolute;
    left: -130px;
    bottom: -60px;
    width: 565px;
    height: 565px;
    z-index: -1;
    border-radius: 50%;
    background-color: #fff7f0;
}

.contact-page-section .form-column .pattern-layer-one {
    position: absolute;
    right: -40px;
    top: -25px;
    width: 106px;
    height: 70px;
    z-index: -1;
    background-repeat: no-repeat;
}

.contact-page-section .form-column .pattern-layer-two {
    position: absolute;
    left: -50px;
    bottom: -25px;
    width: 106px;
    height: 153px;
    z-index: -1;
    background-repeat: no-repeat;
}

.contact-page-section .form-column h2 {
    position: relative;
    color: #06142d;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.contact-page-section .form-column h2:before {
    position: absolute;
    content: '';
    right: 20px;
    top: 0px;
    width: 91px;
    height: 38px;
    /* background: url(../images/icons/contact-arrow.png); */
}

.contact-page-section .form-column .text {
    position: relative;
    color: #181818;
    font-size: 16px;
    line-height: 1.8em;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 30px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
    position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    height: 55px;
    font-size: 14px;
    border-radius: 3px;
    background: #ffffff;
    border: 1px solid transparent;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
    border-color: #43b97e;
}

.contact-form .form-group textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 24px;
    padding: 15px 25px 25px;
    color: #888888;
    height: 185px;
    background: #ffffff;
    resize: none;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid transparent;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.contact-form .theme-btn {
    cursor: pointer;
    margin-top: 10px;
    text-transform: uppercase;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
    border-color: #ff0000 !important;
}

.contact-form label.error {
    display: block;
    line-height: 24px;
    padding: 5px 0px 0px;
    margin: 0px;
    text-transform: uppercase;
    font-size: 12px;
    color: #ff0000;
    font-weight: 500;
}

.form-group {
    margin-bottom: 15px;
}

.page-banner-section {
    min-height: auto;
    padding: 50px 0;
    margin-bottom: 50px;
}

.page-banner-content .title {
    font-size: 26px;
}

.offcanvas.offcanvas-start {
    transition: all 0.5s;
}

.offcanvas.offcanvas-start.show {
    height: 100%;
}

/*popup*/
/**/
.modal-content.newspaper li {
    width: calc(33.33% - 20px);
    display: inline-block;
    padding: 8px 15px;
    border: 1px solid #fff;
    margin: 5px 8px;
}

.modal-content.newspaper li.active {
    border-color: #333;
    color: #000;
    font-weight: 600;
}

#modal-login .modal-dialog.modal-dialog-lg {
    max-width: 540px !important;
}

#modal-login form input {
    background: #f2f2f2 !important;
    padding-left: 18px !important;
    color: #000 !important;
    font-size: 16px !important;
    border: 0 !important;
    height: 40px;
}

.dropdown-select {
    height: 40px;
}

#modal-login h3 small {
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: normal;
    color: #333;
}

#modal-login .form-lang-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #333;
    background: #f2f2f2;
}

#modal-login .form-group {
    display: flex;
    align-items: stretch;
    border: 0;
}

.contact-form button#dropdownMenuButton {
    border: 0;
    padding: 0 15px;
    font-weight: bold;
    background: #dedede;
    height: 100%;
    color: #000;
    border-radius: 0;
    width: 100px;
    vertical-align: middle;
    line-height: 40px;
}

#modal-login .no-bg-wrapper {
    background: none;
    justify-content: center;
}

#modal-login .form-group .btn-primary {
    padding: 15px 20px;
    height: auto;
    line-height: 15px;
}

button.close {
    border: 0;
    background: transparent;
    font-size: 29px;
    position: absolute;
    right: 20px;
    top: 30px;
}

.modal-body {
    border: 0;
    border-radius: 0;
}

.modal-content {
    border: 0;
    border-radius: 0;

}


.modal-login #modal-login .form-group.enter-the-otp {
    margin: 0 auto 10px auto;
    font-size: 14px;
}

.modal-header {
    border: 0;
    padding: 30px 30px 0 30px !important;
    align-items: center;
}

.dropdown-menu {
    border: 0;
    border-radius: 0;
    font-size: 14px;
    right: 0;
}

.dropdown-menu img {
    width: 30px;
    margin-right: 8px;
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.dont-have-account {
    text-align: center;
    font-size: 14px;
}

.dont-have-account a {
    text-decoration: underline;
    color: #103675;
    font-weight: 500;
}

.contact-form button#dropdownMenuButton img {
    width: 21px;
    margin-right: 6px;
}

/*stepwizard
  ===============================================*/

.stepwizard-step p {
    margin-top: 10px;
}

.stepwizard-row {
    display: flex;
    justify-content: space-between;
}

.stepwizard {
    display: table;
    width: 100%;
    position: relative;
}

.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: calc(100% - 110px);
    height: 3px;
    background-color: rgb(0 0 0 / 10%);
    left: 50%;
    transform: translateX(-50%);
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 0;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    border-radius: 15px;
}

.step-button {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 0;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    border-radius: 50% !important;
}

.step-button.btn.btn-default.btn-circle {
    background: #f2f2f2;
    border-radius: 50px;
}

a.btn.btn-circle {
    background: #f2f2f2;
    border-radius: 50px;
}

.step-button.btn.btn-circle.btn-primary {
    background: #009688;
}

.setup-content {
    margin-top: 20px;
}

.error {
    font-size: 12px;
}

body a.btn.btn-circle.btn-primary {
    background: #009688;
}

.stepwizard-step p {
    font-weight: bold;
    font-size: 12px;
}

.stepwizard-step {
    width: 110px;
    text-align: center;
}

a.btn.btn-default.btn-circle {
    background: #f2f2f2;
    border-radius: 50px;
}

.form-group.enter-the-otp {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.form-group.enter-the-otp h6 {
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.form-group.otp-box {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
}

.form-group.otp-box input {
    flex: 0 0 250px;
    margin: 0 8px;
    font-weight: 600;
    color: #000;
    letter-spacing: 5px;
    text-align: center;
}

.modal-login #modal-login .form-group.otp-box input {
    max-width: 150px;
    text-align: center !important;
    padding: 0 !important;
    margin: 0 auto;
}

.opt-box>.error {
    text-align: center !important;
}

div#language .modal-dialog {
    max-width: 550px;
}

li.select-lang a:hover i {
    color: #fff;
}

.resend-OTP {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 0 18px;
    margin-top: 11px;
    font-weight: 600;
    color: #009688;
}

.section,
.main-wrapper {
    float: none;
}

.modal-login #modal-login .form-group.otp-box {
    margin: 0 auto 10px auto;
}

.modal-login #modal-login .form-group.otp-box {
    display: block;
}

section.instructor-blocks-section {
    padding: 50px 0 0 0;
}


section.instructor-blocks-section .row {
    align-items: center;
}

section.instructor-blocks-section h3 {
    font-size: 26px;
    margin-bottom: 15px;
}

.banner-caption.instructor-wrapper {
    padding-top: 0;
    padding-left: 40px;
}

.banner-caption.instructor-wrapper p {
    font-size: 18px;
}

.single-course-list {
    background: #f8f8f8;
    margin: 25px 0 25px 0;
    border-radius: 10px;
    border: 0 !important;
    box-shadow: 0px 0px 14px 4px rgb(0 0 0 / 8%);
    border-radius: 5px;

}

.sidebar-wrap-02 {
    border-radius: 0;
    background: #fff;
    padding: 0 0;
    padding-bottom: 30px;
    border: 0;
}

h3.widget-title {
    background: #f3f3f3;
    font-size: 16px;
    padding: 8px 20px;
    font-weight: 500;
}

ul.checkbox-list {
    padding: 0 20px 15px 20px;
}

.nav .active i {
    color: #103675;
}

.course-collapse {
    border-color: #f3f3f3;
    border-radius: 0;
    background: #fff;
    padding: 20px 20px;
}

.single-course {
    border-radius: 0 !important;
    box-shadow: none;
    border: 1px solid #e1e1e1;
}

.single-course .courses-image {
    border-radius: 0;
    height: 180px;
    position: relative;
}

.single-category .category-content .category-link {
    border-radius: 0;
    background-color: #103675;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    border-radius: 50%;
}

ul#myTab {
    justify-content: center;
    border: 0;
}

.nav-tabs .nav-item {
    border-bottom: 3px solid #103675;
}

.nav-tabs .nav-link {
    border: 0 !important;
    border-radius: 0;
    color: #103675;
    font-weight: 600;
    background: #f2f2f2;
    padding: 5px 30px;
    font-size: 16px;
    text-transform: uppercase;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #103675;
    font-weight: bold;
}

.sidebar-description form select {
    border: 1px solid #333;
    width: 100%;
    height: 41px;
    border-radius: 0;
    padding: 0 11px;
}

.sidebar-description form {
    margin-top: 15px;
}


/*about-us
  =======================*/
.about-banner .title small {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.about-banner .title small h1 {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.about-banner .courses-btn {
    max-width: 430px;
}

.about-banner .courses-btn a {
    flex: 0 0 50%;
}

.section.page-banner-section.bg-color-1.blog-banner {
    background-blend-mode: overlay;
    background-position: 100% 100%;
    background-size: cover;
    padding: 200px 0 100px 0;
    margin-bottom: 0;
    z-index: 1;
}

.section.page-banner-section.bg-color-1 {
    background-position: 100% 100%;
    background-size: cover;
    padding: 200px 0 100px 0;
    margin-bottom: 0;
    z-index: 1;
}

.section.page-banner-section.bg-color-1.course_detail_banner {
    /* background-image: url(../images/banner-2.png); */
    background-position: 100% 100%;
    background-size: cover;
    padding: 200px 0 100px 0;
    margin-bottom: 0;
    z-index: 1;

    background-position: right;
    background-size: 55% 100%;
    padding: 200px 0 100px 0;
    margin-bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    position: relative;
}

.detail_category_part {
    display: flex;
    align-items: center;
}

.detail_category_part p:nth-child(2) {
    padding-left: 10px;
}

.about-banner .title {
    margin-top: 0;
}

.sticky-bar-comm {
    background-color: black;
    position: absolute;
    background-color: #f3f8fe;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    bottom: 0;
    width: 90%;
    transform: translate(-50%, 25px);
    left: 50%;
    height: 80px;
    display: flex;
    align-items: center;
}

.sticky-bar-sticky {
    z-index: 100000;
    position: fixed;
    top: 37px;
    bottom: auto;
    z-index: 10000;
    width: 100%;
    background: #103675;
    box-shadow: none;
    transition: 0.5s all;
    opacity: 1 !important;
}

.sticky-bar-comm ul {
    display: flex;
    justify-content: center;
}

.sticky-bar-comm ul li a {
    margin: 0 15px;
    font-size: 18px;
    border-bottom: 0;
    color: #000;
    position: relative;
}

.main_newblock {
    position: relative;
    z-index: 3;
}

.sticky-bar-sticky ul li a {
    color: #fff;
}

section.about-info {
    padding: 50px 0 50px 0;
}

.wrapper-info-about p,
.wrapper-info-about h2 {
    font-size: 16px;
    margin-bottom: 14px;
    font-weight: normal;
    color: #666666;
}

.wrapper-info-about h3 {
    margin-bottom: 15px;
}

.res-s-bttm {
    width: calc(50% - 20px);
    margin: 10px 10px;
    padding: 10px 10px;
    display: flex;
    text-align: left;
    flex-wrap: wrap;
}

.res-s-bttm h4 {
    font-size: 18px;
    font-weight: 600;
}

.feature-intro .icon-box img {
    width: 100% !important;
    height: 100%;
    margin-right: 20px;
}

.feature-intro .icon-box {
    width: 65px;
    height: 65px;
}

.text-box-new {
    text-align: left;
}

section.about-us-trial {
    padding: 70px 0;
}

section.about-us-trial h2 {
    margin-bottom: 40px;
}

* {
    scroll-margin-top: 13em;
}

section.about-info,
#why-choose,
#our-mission {
    scroll-margin-top: 10em;
}

/*conatct
  =====================*/


.contact-page-section {
    position: relative;
    padding: 70px 0px 70px;
}

.contact-page-section .pattern-layer-three {
    position: absolute;
    left: 10%;
    top: 55%;
    width: 45px;
    height: 43px;
    background-repeat: no-repeat;
}

.contact-page-section .info-column {
    position: relative;
    margin-bottom: 30px;
}

.contact-page-section .info-column .inner-column {
    position: relative;
}

.contact-page-section .info-column .title {
    position: relative;
    color: #009688;
    font-size: 16px;
    text-transform: uppercase;
}

.contact-page-section .info-column h2 {
    position: relative;
    color: #06142d;
    font-weight: 700;
    line-height: 1.2em;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 22px;
}

.contact-page-section .info-column .text {
    position: relative;
    color: #181818;
    font-size: 18px;
    line-height: 1.7em;
    margin-bottom: 18px;
}

.contact-page-section .info-column ul {
    position: relative;
}

.contact-page-section .info-column ul li {
    position: relative;
    color: #06142d;
    font-size: 16px;
    line-height: 1.7em;
    margin-bottom: 17px;
}

.contact-page-section .info-column ul li span {
    position: relative;
    display: block;
    color: #b40f23;
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.contact-page-section .form-column {
    position: relative;
    margin-bottom: 30px;
}

.contact-page-section .form-column .inner-column {
    position: relative;
    padding: 40px 35px;
    background-color: #f4f7fa;
}

.contact-page-section .form-column .circle-layer {
    position: absolute;
    left: -130px;
    bottom: -60px;
    width: 565px;
    height: 565px;
    z-index: -1;
    border-radius: 50%;
    background-color: #fff7f0;
}

.contact-page-section .form-column .pattern-layer-one {
    position: absolute;
    right: -40px;
    top: -25px;
    width: 106px;
    height: 70px;
    z-index: -1;
    background-repeat: no-repeat;
}

.contact-page-section .form-column .pattern-layer-two {
    position: absolute;
    left: -50px;
    bottom: -25px;
    width: 106px;
    height: 153px;
    z-index: -1;
    background-repeat: no-repeat;
}

.contact-page-section .form-column h2 {
    position: relative;
    color: #06142d;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.contact-page-section .form-column h2:before {
    position: absolute;
    content: '';
    right: 20px;
    top: 0px;
    width: 91px;
    height: 38px;
    /* background: url(../images/icons/contact-arrow.png); */
}

.contact-page-section .form-column .text {
    position: relative;
    color: #181818;
    font-size: 16px;
    line-height: 1.8em;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 30px;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
    position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    height: 55px;
    font-size: 14px;
    border-radius: 3px;
    background: #ffffff;
    border: 1px solid transparent;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
    border-color: #43b97e;
}

.contact-form .form-group textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 24px;
    padding: 15px 25px 25px;
    color: #888888;
    height: 185px;
    background: #ffffff;
    resize: none;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid transparent;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.contact-form .theme-btn {
    cursor: pointer;
    margin-top: 10px;
    text-transform: uppercase;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
    border-color: #ff0000 !important;
}

.contact-form label.error {
    display: block;
    line-height: 24px;
    padding: 5px 0px 0px;
    margin: 0px;
    text-transform: uppercase;
    font-size: 12px;
    color: #ff0000;
    font-weight: 500;
}

section.contact-page-section .auto-container {
    max-width: 1160px;
}

body .btn {
    border-radius: 0;
}

.text strong {
    color: #b40f23;
}

.sticky-bar-sticky li a {
    border-color: #ffffff30 !important;
}

.section.page-banner-section.contact-banner {
    /* background-image: url(../images/banner-1.png); */
}

/*acodian*/
.accordion .panel-title a {
    color: #ec2128;
    padding: 16px 30px 17px 0;
}

.accordion .panel-title a .plus-minus {
    opacity: 0.4;
}

.accordion .panel-title a.collapsed {
    color: #333;
}

.accordion .panel-title a.collapsed:hover {
    opacity: 0.7;
}

.accordion .panel-title a.collapsed:hover .plus-minus {
    opacity: 0.4;
}

.accordion .panel-title a.collapsed .plus-minus {
    opacity: 0.7;
}

.accordion .panel-title a.collapsed .plus-minus span:before {
    transform: rotate(-180deg);
}

.accordion .panel-title a.collapsed .plus-minus span:after {
    transform: rotate(0deg);
}


.accordion .plus-minus {
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 350ms;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    height: 18px;
    width: 18px;
    margin-top: -9px;
}

.accordion .plus-minus span {
    position: relative;
    height: 100%;
    display: block;
}

.accordion .plus-minus span:before,
.accordion .plus-minus span:after {
    content: "";
    position: absolute;
    background: #666;
    transition: transform 100ms;
}

.accordion .plus-minus span:before {
    height: 2px;
    width: 100%;
    left: 0;
    top: 50%;
    margin-top: -1px;
    transform: rotate(180deg);
}

.accordion .plus-minus span:after {
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px;
    transform: rotate(90deg);
}

.accordion .plus-minus:hover {
    opacity: 0.3;
}

.accordion .panel-title a {
    color: #0c3d68;
    padding: 12px 20px 12px 0;
    position: relative;
}

.panel-title>a {
    display: block;
    padding: 15px;
    text-decoration: none;
}

.panel.panel-default {
    background: #fff;
    padding: 0;
    margin-bottom: 15px;
}

.accordion .panel-collapse {
    padding: 16px 25px 0 25px;
}

.accordion .panel-title a {
    position: relative !important;
}

.panel-body ul li {
    font-size: 16px;
    display: flex;
    margin-bottom: 3px;
}

.panel-body ul li i {
    margin-right: 15px;
    margin-top: 5px;
    color: #0c3d68;
    list-style: disc;
}

.products-details-blocks .sticky-bar-comm {
    opacity: 0;
    z-index: 100000;
    position: fixed;
    top: 37px;
    bottom: auto;
    z-index: 10000;
    width: 100%;
    background: #103675;
    box-shadow: none;
    transition: 0.5s all;
}

.products-details-blocks .sticky-bar-comm.sticky-bar-sticky {
    display: flex;
}


.professional-certificate .profile-images {
    width: 90px;
    height: 90px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.professional-certificate .profile-images h2 {
    width: 100%;
    line-height: 1;
}

.professional-certificate .profile-images h4 {
    font-weight: 400;
}

.professional-certificate .course-instructor {
    background: #f2f2f2;
    padding: 30px 30px;
    margin-bottom: 25px;
}

.course-details-wrapper .course-learn-list ul li {
    line-height: 1.4;
    width: 100%;
    margin-bottom: 14px;
}

.share-certficate li {
    display: inline-block;
    width: auto !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.share-certficate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.share-certficate ul.social-box {
    padding: 0;
    margin-left: 20px;
}

.accordion .panel-heading {
    background: #f2f2f2;
    padding: 0 25px;
}

h4.panel-title {
    font-size: 16px;
}

.lessons-top {
    margin-bottom: 15px;
}

.frequently-asked-questions {
    padding: 80px 12.2%;
    padding-bottom: 0;
}

.modal {
    z-index: 999990;
}

.modal-backdrop {
    z-index: 999;
}

h3.banner-caption__title small {
    display: block;
    font-weight: normal;
    font-size: 14px;
    color: #282828;
    letter-spacing: 0.5px;
}

.banner-caption.instructor-wrapper a.btn {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-caption.instructor-wrapper .courses-btn {
    justify-content: flex-start;
}


.wrapper-brochures {
    display: flex;
    justify-content: space-between;
    background: #d6f0f3;
    align-items: center;
    margin-top: 50px;
    padding: 30px 30px;
}

.wrapper-brochures h4 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: #0c3d68;
    margin-bottom: 15px;
}

.wrapper-brochures p {
    margin: 0;
    width: 80%;
}

.download-btn ul {
    margin: 0;
}

.download-btn ul li {
    margin: 7px 0;
}

.download-btn ul li a {
    background: #0c3d68;
    width: 100%;
    display: flex;
    color: #fff;
    font-size: 16px;
    padding: 14px 30px;
    text-decoration: none;
    align-items: center;
}

.download-btn ul li a svg {
    margin-right: 20px;
    font-size: 32px;
}

.download-btn ul li:nth-child(2) a {
    background: #28a745;
}

.career-text .text-box-new p {
    width: 100%;
}

.recruiters-profile img {
    width: 170px;
    margin-right: 15px;
}

.recruiters-profile {
    border-top: 1px solid #f1f1f1;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    border-bottom: 1px solid #f1f1f1;
}

.career-openings .panel-body h2 {
    font-size: 20px;
    color: #0c3d68;
    margin-top: 36px;
    font-weight: 600;
}

.career-openings .panel-body {
    padding-top: 20px;
    padding-bottom: 20px;
}

.career-openings .panel-body h2:first-child {
    margin-top: 0;
}

.career-openings .panel-body ul {
    padding-left: 15px;
    border-left: 3px solid #f1f1f1;
    margin-top: 10px;
    display: inline-block;
    margin-bottom: 0;
}

.career-openings .panel-body ul li:last-child {
    margin-bottom: 0;
}

.img-carrer-inner-banner .container img {
    width: 100%;
}

.gray-bg {
    background: #f9f9f9;
}

.ca-vartical-menu .icon-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.ca-vartical-menu .icon-box h2 {
    margin: 0;
    font-size: 20px;
}

.ca-vartical-menu .icon-box h2 span {
    display: block;
    font-weight: bold;
}

.about-us-trial .icon-box img {
    width: 65px;
    margin-right: 20px;
}

.ca-vartical-menu .icon-box h2 {
    margin: 0;
    font-size: 20px;
    font-weight: normal;
}

.hover-div-menu {
    display: none;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
}

.header-menu-hover+.hover-div-menu {
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 25%);
    left: 0;
    top: 100px;
    z-index: -1;
    display: block;
}

.header-menu .sub-menu {
    max-height: none;
    min-height: fit-content;
    height: 420px;
}

.header-menu .sub-menu ul.sub-menu {
    width: 800px;
    transform: translateX(0);
    padding: 40px 50px;
    max-width: 740px;
    height: 420px;
    overflow-y: scroll;
}

.header-logo ul.main-menu li ul.sub-menu h6 {
    padding: 0 29px;
    margin-bottom: 15px;
    font-size: 16px;
}

.header-menu .sub-menu ul.sub-menu li h4 {
    font-size: 18px;
}

.uni-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.uni-wrapper img {
    margin: 4px 15px 0 0;
    width: 28px !important;
}

.block-uni {
    width: calc(100% - 51px);
    line-height: 1;
}

.block-uni h3 {
    text-transform: capitalize;
    font-size: 14px;
    white-space: break-spaces;
    line-height: 1.2;
}

.block-uni small {
    font-size: 14px;
}

.header-menu .sub-menu ul.sub-menu h5 {
    border-top: 1px solid #d3d3d3;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    white-space: break-spaces;
    font-size: 14px;
    height: 60px;
}

.header-menu .sub-menu ul.sub-menu li h4+p {
    font-size: 14px;
}

a.view-all-menu {
    padding: 4px 20px !important;
    color: #103675 !important;
    font-size: 14px !important;
    background: #f2f2f2;
}

.arrow-icon {
    position: relative;
}

.arrow-icon+span.menu-icon {
    display: none;
}

ul.col-menu li a {
    padding: 0 0 !important;
    white-space: break-spaces;
    line-height: 1.2;
    margin-bottom: 13px;
    font-size: 14px !important;
}

.section.page-banner-section.enterprise-banner {
    /* background-image: url(../images/enterprise.png); */
}

section#our-mission .text {
    padding: 0 12.5%;
}

.add-ons .inner-box {
    text-align: left;
    padding: 50px 50px;
    height: 100%;
}

.add-ons .inner-box h5 {
    margin: 0;
}

.add-ons .inner-box ul i {
    color: #333;
    margin-right: 15px;
}

.add-ons .inner-box ul li {
    color: #333;
}

.price {
    position: absolute;
    top: 36px;
    right: 30px;
    color: #b91f32;
    font-size: 27px;
    font-weight: 600;
}

.add-ons .inner-box a.btn {
    width: 100%;
    margin-top: 30px;
}

.add-ons .auto-container {
    max-width: 1000px;
}

.btn-hover-heading-color:hover {
    background-color: #56c45a;
    border-color: #4caf50;
    color: #ffffff;
}

.left-space {
    padding-left: 70px;
}

.wrapper-info-about h6 {
    font-weight: normal;
}

.text-box-new p {
    font-size: 16px;
    line-height: 1.5;
}

.toop-head {
    text-align: right;
    background: #f2f2f2;
    font-size: 12px;
    padding: 3px 30px;
    color: #333;
    font-weight: 500;
}

.notice-board {
    padding-top: 100px;
}

select.form-control {
    height: auto !important;
    font-size: 14px;
    color: gray;
    padding: 13px;
    -moz-appearance: none;
    background: transparent;
    /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 9px;
    overflow: hidden !important;
    white-space: pre;
    text-overflow: ellipsis;
    padding-right: 100px;
}

.form-control {
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #cdcdcd;
    font-size: 16px;
    color: #333;
    padding: 0px 10px;
    letter-spacing: 1px;
    border-radius: 0;
    appearance: auto;
    height: 30px;
    line-height: 30px;
}

.course-detail h3, .course-detail h2 {
    margin: 0;
    font-size: 20px;
}

table.time-table tr,
table.time-table td,
table.time-table tr,
table.time-table th {
    border: 1px solid #d1d1d1;
    font-size: 14px;
    padding: 8px 26px;
}

table.time-table th {
    background: #fff;
    font-size: 16px;
    white-space: nowrap;
}

table.time-table tr:last-child,
table.time-table td:last-child,
table.time-table tr:last-child,
table.time-table th:last-child {}

table.time-table {
    margin-top: 30px;
    width: 100%;
}

.sidebar-details-video-description .sidebar-description .price {
    position: static;
}

h2.title small {
    font-weight: normal;
    font-size: 14px;
}

.government-page .inner-box {
    padding: 0;
}

.government-space {
    padding: 40px 50px;
}

.workforce {
    padding: 50px 0;
}

form#contact-form {
    max-width: 800px;
    margin: 50px auto 0 auto;
}

form#contact-form input,
form#contact-form textarea {
    background: #fff;
    border: 0;
    font-size: 14px;
    padding: 11px 18px;
    width: 100%;
    color: #000;
    border: 1px solid #cbcbcb;
}

form#contact-form p {
    font-size: 14px;
    line-height: 1.5;
}

.form-group.reg {
    flex-wrap: wrap;
}

.form-group.reg select {
    border: 0;
    border-radius: 0;
    padding: 0 20px;
    background: #f2f2f2;
    appearance: none;
    height: 40px !important;
    line-height: 40px !important;
}

.form-group.reg::after {
    content: "\f107";
    font: normal normal normal 21px/1 FontAwesome;
    position: absolute;
    bottom: 9px;
    right: 20px;
}

div#register form#contact-form {
    margin-top: 0;
}

div#register form#contact-form label {
    color: #333;
    font-size: 10px;
    margin-bottom: 16px;
}

.form-group.reg {
    position: relative;
}

.modal-content.newspaper form#contact-form {
    margin-top: 0;
}

.section.page-banner-section.bg-color-1.gov-banner {
    /* background-image: url(../images/government-banner.png); */
}

.top-meta span.price {
    position: static;
    font-size: initial;
}

.sidebar-course-item .item-content .price {
    position: static;
}


.dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.dropdown-toggle.author {
    border-radius: 50%;
    overflow: hidden;
    border: 0;
    width: 40px;
    height: 40px;
    object-fit: fill;
}

/*drop-down*/
.user-profile-wrap {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* border-bottom: 1px solid #000; */
    padding: 5px;
}

.img-wrapp {
    width: 1.2rem;
    height: 1.2rem;
    background: #B5B8BD;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 13px;
    margin-right: 10px;
}

.header-login .dropdown-menu {
    background: #FFFFFF;
    border: 1px solid #f1f1f1fd;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.header-login .dropdown-inner{
    padding: 5px;
}
.header-login .dropdown-menu .mui-icon{
    font-size: 01rem;
    color:#B5B8BD ;
    font-weight: 500;
}
.name-text-wrapp span {
    font-size: 14px;
    font-weight: 500;
    color: #103675;
    cursor: pointer;
}

/* .name-text-wrapp {
    line-height: 1.4;
    border-bottom: 1px solid #000;
} */

.email-text-wrapp {
    font-size: 12px;
    letter-spacing: 0.5px;
}

.header-login ul li a {
    font-size: 16px;
}

.header-login ul li a {
    margin: 0px 0;
    padding: 5px;
    margin: 2px;
    font-size: 0.8rem;
    font-weight: 400;
    color: #000;
}

.header-login ul {
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada;
    /* margin-top: 10px; */
}
.header-login li:hover{
    background-color: #F3F3F9;
    color: #00184f;
}
.header-login ul:last-child {
    padding-bottom: 0;
    border: 0;
}

.header-login ul li a span {
    font-weight: bold;
}

.certificate img {
    width: 170px;
}

img.iso {
    width: 97px;
}

li.notification a i {
    font-size: 24px;
}

li.notification a {
    width: 50px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

li.notification {
    padding-left: 0 !important;
}

.section.profile-banner-after-login {
    background: #f2f2f2;
    padding: 140px 0 30px 0;
}

ul.breadcrumb {
    margin-bottom: 0;
}

/*profile*/
.profile-view .profile-basic {
    margin-left: 140px;
    padding-right: 50px;
}

.profile-info-left {
    border-right: 2px dashed #ccc;
}

.user-name {
    color: #333;
    font-size: 18px;
}

.text-muted {
    color: #6c757d !important;
    font-size: 16px;
}

.staff-id {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}

.staff-msg {
    margin-top: 30px;
}

.profile-view .profile-img-wrap {
    height: 120px;
    width: 120px;
}

.profile-img-wrap {
    height: 120px;
    position: absolute;
    width: 120px;
    overflow: hidden;
}

.profile-view .profile-img {
    width: 120px;
    height: 120px;
}

.profile-img {
    cursor: pointer;
    height: 80px;
    margin: 0 auto;
    position: relative;
    width: 80px;
}

.profile-img-wrap img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
}

.personal-info {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.personal-info li {
    margin-bottom: 10px;
    padding: 6px 0;
}

.personal-info li .title {
    color: #4f4f4f;
    float: left;
    font-weight: 500;
    margin-right: 30px;
    width: calc(50% - 30px);
    font-size: 16px;
    line-height: 1;
}

.personal-info li .text {
    color: #8e8e8e;
    display: block;
    overflow: hidden;
}

.personal-info li .text {
    color: #8e8e8e;
    display: block;
    overflow: hidden;
    line-height: 1.5;
    width: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.avatar-box {
    float: left;
}

.avatar.avatar-xs {
    width: 24px;
    height: 24px;
}

.avatar {
    background-color: #aaa;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    margin: 0 10px 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 38px;
    position: relative;
    white-space: nowrap;
}

.avatar>img {
    border-radius: 50%;
    display: block;
    overflow: hidden;
    width: 100%;
}

.profile-page a {
    color: #103675;
    font-weight: 500;
    text-decoration: none;
}

.tab-box {
    border-bottom: 0;
    margin-bottom: 10px;
    padding: 0;
}

.card {
    border: 1px solid #ededed;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    margin-bottom: 30px;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.card .card-title {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.edit-icon {
    background-color: #00000029;
    border: 1px solid #e3e3e3;
    color: #bbb;
    float: right;
    font-size: 12px;
    line-height: 24px;
    min-height: 26px;
    text-align: center;
    width: 26px;
    border-radius: 24px;
}

.edit-icon i {
    color: #103675;
}

.table {
    padding: 0;
    background: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.profile-page a.btn {
    color: #fff;
}

.profile-page {
    padding: 80px 15px;
    display: flex;
    max-width: calc(100% - 30px);
    margin: 100px auto 0 auto;
}

.card.tab-box {
    border: 0 !important;
    box-shadow: none !important;
}

ul.nav.nav-tabs.nav-tabs-bottom {
    border: 0;
}

.card {
    border: 1px solid #f2f2f2 !important;
    background: #f2f2f26e;
    margin-bottom: 40px !important;
    padding: 8px;
    box-shadow: none !important;
}

ul.nav.nav-tabs.nav-tabs-bottom {
    justify-content: center;
}

.nav-tabs .nav-link {
    background: #ffffff;
}

.team-members {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.team-members>li:first-child a {
    margin-left: 0;
}

.team-members>li>a {
    border: 2px solid #fff;
    border-radius: 100%;
    display: block;
    height: 30px;
    overflow: hidden;
    width: 30px;
}

.team-members .all-users {
    line-height: 28px;
    opacity: 0.8;
}

.all-users {
    background-color: #ff5e3a;
    color: #fff !important;
    font-size: 10px;
    font-weight: 800;
    line-height: 34px;
    text-align: center;
}

.all-users:hover,
.all-users:active {
    color: #fff;
}

.team-members img {
    width: 100%;
}

.project-title {
    margin: 0 0 5px;
}

.project-title>h5 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
}

.project-title>a {
    color: #333;
}

.avatar-dropdown .dropdown-menu {
    min-width: unset;
    width: 100px;
    padding: 0;
}

.avatar-dropdown .avatar-group {
    min-height: 88px;
    padding: 2px;
    white-space: normal;
}

.avatar-dropdown .avatar-group .avatar {
    margin: 2px;
}

.avatar-dropdown .avatar-pagination {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar-dropdown .page-link {
    padding: .3rem 0.55rem;
    border-radius: 0 !important;
    border: 0;
}

.avatar-dropdown .dropdown-toggle::after {
    display: none;
}


.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-xs {
    height: 4px;
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

select.select {
    width: 100%;
    border: 1px solid #ccc;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    height: 28px !important;
    letter-spacing: 0;
}

label.col-form-label {
    color: #000;
}

.side-bar-left {
    width: 100%;
    margin-right: 50px;
    max-width: 350px;
    background: #fff;
}

.side-bar-left .user-profile-wrap {
    padding: 30px 30px;
    background: #f2f2f2;
}

.side-bar-left ul {
    padding: 20px 20px;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
}

.side-bar-left ul a {
    color: #333;
    font-weight: normal;
    font-size: 18px;
    padding: 5px 10px 5px 10px;
    width: 100%;
}

.side-bar-left ul a::before {
    content: "\f105";
    font: normal normal normal 22px/1 FontAwesome;
    margin-right: 15px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1320px;
}

.header-login .dropdown:hover>.dropdown-menu {
    display: block;
}

ul.breadcrumb {
    background: transparent;
    padding: 0;
    border-radius: 0;
}

.tab-content .table thead th,
.table td {
    font-size: 16px;
}

.tab-content .table-responsive {
    overflow: unset;
}

h5.section-title {
    margin-bottom: 15px;
}

ul.experience-list li {
    background: #fff;
    margin-bottom: 15px;
    padding: 14px 15px;
}

span.time {
    display: block;
}

.profile-action {
    text-align: right;
    position: absolute;
    top: 30px;
    right: 18px;
}

.profile-img-wrap.edit-img {
    border-radius: 50%;
    margin: 0 auto 30px;
    position: relative;
}

.profile-img-wrap {
    height: 120px;
    position: absolute;
    width: 120px;
    background: #ffffff;
    overflow: hidden;
}

.custom-modal label {
    font-size: 14px;
    font-weight: normal;
    color: #222;
    letter-spacing: 0.5px;
}

.submit-section button {
    max-width: 200px;
    width: 100%;
}

.profile-img-wrap .fileupload {
    background: rgb(16 54 117);
    border: none;
    padding: 3px 10px;
    border-radius: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

.profile-img-wrap .fileupload .upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    opacity: 0;
    margin: 0;
    padding: 5px;
    position: absolute;
    top: -3px;
    right: -3px;
}

.profile-img-wrap span.btn-text {
    color: #fff;
}

.custom-modal .modal-body {
    padding-top: 0;
}

.custom-modal .modal-body .card,
.custom-modal .modal-body .card .card-body {
    padding: 0;
    border: 0 !important;
    background: transparent;
}

.learning-wrapper ul.nav.nav-tabs {
    justify-content: center;
    border: 0;
}

.learning-wrapper {
    padding: 80px 0;
}

.learning-wrapper ul.nav.nav-tabs a.nav-link {
    background: #f2f2f2;
}

.learning-wrapper ul.nav.nav-tabs a.nav-link.active {
    background-color: #103675;
}

.activity-box {
    position: relative;
}

.activity-box .activity-list {
    list-style: none;
    position: relative;
}

.activity-box .activity-list::before {
    content: "";
    width: 2px;
    background: #dddddd;
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 23px;
}

.activity-box .activity-list li {
    background-color: #f5f5f5;
    position: relative;
    border: 1px solid #ededed;
    -webkit-box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    margin: 0 0 10px;
    padding: 10px;
}

.activity-box .activity-list li .activity-user {
    height: 32px;
    width: 32px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 12px;
    left: 8px;
}

.activity-box .activity-list li .activity-user .avatar {
    height: 32px;
    line-height: 32px;
    width: 32px;
    margin: 0;
}

.activity-box .activity-list li .activity-content {
    position: relative;
    margin: 0 0 0 40px;
    padding: 0;
}

.activity-box .activity-list li .activity-content .timeline-content {
    color: #9e9e9e;
}

.activity-box .activity-list li .activity-content .timeline-content a {
    color: #616161;
}

.activity-box .activity-list li .time {
    color: #bdbdbd;
    display: block;
    font-size: 13px;
}

.activity-box .activity-list li::before {
    background: #dddddd;
    bottom: 0;
    content: "";
    width: 2px;
    position: absolute;
    top: 12px;
    left: 22px;
}

.content.right-wrapper-contacnt {
    width: 100%;
}

.account-setting {
    max-width: 800px;
    margin: 0;
    border: 1px solid #f1f1f1;
    padding: 30px 30px;
    position: relative;
}

.account-wrapper p {
    font-size: 16px;
    letter-spacing: 0.5px;
}

.current-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f1f1;
    padding: 20px 20px;
    margin-top: 20px;
}

.current-plan small {
    display: block;
    text-transform: uppercase;
    margin-top: 1px;
}

a.simpale-a-tag {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: #898989;
}

a.simpale-a-tag span {
    margin-right: 9px;
    font-size: 21px;
}

a.simpale-a-tag:hover {
    color: #103675;
}

.right-wrapper-contacnt .table thead th,
.right-wrapper-contacnt .table th {
    font-weight: normal;
    font-size: 16px;
    text-align: right;
    max-width: 150px;
}

.right-wrapper-contacnt .table tr th:last-child {
    text-align: left;
    width: 70%;
    color: #333;
    font-size: 16px;
}

.right-wrapper-contacnt .table thead th,
.right-wrapper-contacnt .table tr th:first-child {
    font-size: 18px;
    color: #7898ce;
}

.right-wrapper-contacnt .table thead th {
    border-bottom: 2px solid #cccccc !important;
}

.right-wrapper-contacnt table.table {
    background: #fff;
    border: 1px solid #d9d9d9;
    margin-top: 20px;
}

span.lang-symb {
    display: flex;
    align-items: center;
    float: right;
    color: #103675;
    font-size: 16px;
}

span.lang-symb span {
    font-size: 18px;
    margin-right: 6px;
}

.document-list {
    display: flex;
    align-items: flex-start;
}

.document-list span {
    background: #2196f3;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    margin-right: 18px;
}

.document-list+a {
    margin-top: 0;
}

.current-plan .btn {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    height: auto;
    line-height: normal;
    padding: 9px 15px;
}

.side-bar-left ul:last-child {
    border-bottom: 1px solid #f2f2f2;
}

ul.my-dashboard-page {
    display: flex;
    flex-wrap: wrap;
}

ul.my-dashboard-page li {
    width: calc(25% - 20px);
    margin: 0 10px 30px 10px;
    padding: 30px 30px;
}

ul.my-dashboard-page li a span {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 14px auto;
    font-size: 48px;
    border-radius: 50%;
}

ul.my-dashboard-page li a {
    width: 100%;
    text-align: center;
}

.box-first-bg {
    background: rgba(76, 175, 80, 0.1);
}

.box-two-bg {
    background: rgb(175 76 76 / 10%);
}

.box-three-bg {

    background: rgb(76 147 175 / 10%);
}

.box-foure-bg {

    background: rgb(175 171 76 / 10%);
}

li.box-first-bg span {
    background: #8bc34a;
    color: #fff;
}

li.box-first-bg a {
    color: #4caf50;
}

/*end*/
li.box-two-bg span {
    background: #f14c4c;
    color: #fff;
}

li.box-two-bg a {
    color: #f14c4c;
}

/*end*/
li.box-three-bg span {
    background: #103675;
    color: #fff;
}

li.box-three-bg a {
    color: #103675;
}

/*end*/
li.box-foure-bg span {
    background: #e35122;
    color: #fff;
}

li.box-foure-bg a {
    color: #e35122;
}

/*file upload*/

.file-upload {
    background-color: #ffffff;
    width: calc(100% - 30px);
    margin: 0 auto 50px auto;
    padding: 20px;
    position: relative;
    height: 140px;
    border: 2px dashed #ccc;
    transition: all 0.5s;
}

.file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #1FB264;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #15824B;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.file-upload-btn:hover {
    background: #1AA059;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
}

.file-upload-btn:active {
    border: 0;
    transition: all .2s ease;
}

.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    left: 0;
}

.drag-text h3 {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 1.1px;
    color: #a4a4a4;
}

.drag-text span {
    font-size: 40px;
    margin: 0 auto;
}

.drag-text {
    text-align: center;
}

.file-upload:hover {
    background: #f2f2f2;
}

.align-center-btn {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    max-width: max-content;
    font-weight: normal !important;
}

.align-center-btn span {
    margin-left: 15px;
}

.accepted-lable {
    color: #4caf50 !important;
}

body .profile-page .btn-outline-primary {
    color: #103675;
}

body .profile-page .btn-outline-primary:hover {
    color: #fff;
}

.leave-wrapper .current-plan {
    flex-wrap: wrap;
}

.leave-wrapper .text {
    font-size: 16px;
    margin-top: 13px;
    line-height: 1.7;
    color: #333;
}

.pending-leave {
    color: #ff9800 !important;
}

.ishue-type {
    color: #03a9f4;
    font-size: 14px;
    background: #03a9f40f;
    display: inline-block;
    padding: 2px 10px;
    margin-top: 13px;
}

.setion-new-wraper {
    display: flex;
    align-items: center;
}

.setion-new-wraper a {
    margin: 10px 10px 10px 0;
}

.complete-text-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    color: #000;
}

.coml-test {
    font-size: 16px;
    width: 100%;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #ccc;
}

.progress {
    height: 12px;
}

.solution-report h5 {
    width: 100%;
    justify-content: space-between;
    display: flex;
    border: 1px solid #ccc;
    margin-bottom: -1px;
    padding: 4px 12px;
    font-weight: normal;
    align-items: center;
}

.result-wrapper {
    width: 100%;
    background: #fff;
    padding: 20px 20px;
    margin-bottom: 20px;
}

.result-wrapper a {
    margin: 15px 15px 0px 0;
}

.solution-report h5 small {
    border-left: 1px solid #ccc;
    padding: 0 0 0 15px;
    margin-left: 15px;
    width: 170px;
    text-align: center;
}

ul.top-seation-menu {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
    flex-wrap: wrap;
}

ul.top-seation-menu a {
    padding: 8px 15px;
    background: #f2f2f2;
    margin: 0 8px;
    width: 100%;
    text-align: center;
}

ul.top-seation-menu a.active {
    background: #009688;
    color: #fff;
}

ul.top-seation-menu li {
    flex: 0 0 20%;
}

.play-video .current-plan {
    flex: 0 0 100%;
}

.play-video-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.batch-video-play {
    display: flex;
    align-items: center;
}

.batch-video-play span {
    margin-right: 15px;
    font-size: 50px;
    color: #009688;
}

.content.right-wrapper-contacnt iframe {
    height: 100%;
    min-height: 1000px;
    max-height: 1000px;
}

.download-pdf p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 30px;
}

.download-pdf span {
    color: #e91e63;
}

.download-pdf .ishue-type span {
    color: #fff;
    margin-left: 15px;
}

.download-pdf .ishue-type {
    background: #009688;
    color: #fff;
    display: flex;
    align-items: center;
    line-height: 2.4;
}

.side-bar-left ul a.active {
    font-weight: bold;
    color: #103675;
}

.solution-report h3 {
    font-size: 16px;
}

.solution-report {
    display: flex;
    align-items: center;
}

.solution-report small {
    margin-left: 25px;
}

.instructor-wrapper a.btn span {
    margin-left: 15px;
}

.download-btn ul li a span {
    margin-left: 23px;
}

/* Our Team
  -------------------------------------------------------*/

.our-team .team-row {
    margin-left: -40px;
    margin-right: -40px;
}

.our-team .team-wrap {
    padding: 0 40px;
}

.our-team .container-fluid {
    padding: 0 50px;
}

.team-img img {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    overflow: hidden;
    width: 100%;
}

.team-member,
.team-img {
    position: relative;
    overflow: hidden;
}

.team-title {
    margin: 30px 0 7px;
    font-size: 20px;
}

.overlay {
    background-color: rgba(20, 20, 20, .7);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.team-details {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 5%;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.team-details p {
    color: #fff;
}

.team-img:hover .team-details {
    opacity: 1;
    margin-top: -80px;
}

.team-img:hover .overlay {
    opacity: 1;
}

.socials a {
    display: inline-block;
    width: 37px;
    height: 37px;
    background-color: transparent;
}

.socials i {
    line-height: 37px;
    color: #616161;
    font-size: 14px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.team-details .socials i {
    color: #fff;
}

.socials a:hover i {
    color: #fff;
    background-color: #355c7d;
}

.right-wrapper-contacnt .pro-overview .table thead th,
.right-wrapper-contacnt .pro-overview .table tr th:first-child,
.right-wrapper-contacnt .pro-overview .table tr td {
    font-size: 14px;
    max-width: none !important;
    white-space: nowrap;
}

#powerTip {
    z-index: 99 !important;
}





.checkout-page-style .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 15px;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 30px;
}

.checkout-page-style input {
    background: #F5F5F5;
    border-radius: 5px;
    border: 0 none;
    height: 60px;
    padding: 0 25px;
    font-weight: 500;
    color: #6f6b80;
    border: 1px solid transparent;
    outline: none;
    font-size: 16px;
    top: 0;
}

.checkout-page-style textarea {
    background: #F5F5F5;
    border-radius: 5px;
    border: 0 none;
    padding: 15px 25px;
    font-weight: 500;
    color: #6f6b80;
    min-height: 170px;
    resize: none;
    border: 1px solid transparent;
}

input[type="checkbox"]~label,
input[type="radio"]~label {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    padding-left: 34px;
    cursor: pointer;
    margin-bottom: 0;
}

input[type="checkbox"]:checked~label::before,
input[type="radio"]:checked~label::before {
    background-color: #103675;
    border-color: #103675;
}

input[type="radio"]~label::before {
    border-radius: 50%;
}

input[type="checkbox"]~label::before,
input[type="radio"]~label::before {
    content: " ";
    position: absolute;
    top: 1px;
    left: -3px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: all .3s;
}

input[type="radio"]~label::after {
    width: 8px;
    height: 8px;
    left: 3px;
    top: 7px;
    background: #fff;
    border-radius: 50%;
}

input[type="checkbox"]~label::after,
input[type="radio"]~label::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 1px;
    width: 12px;
    height: 8px;
    background-color: #ffffff00;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all .3s;
}

.checkout-page-style .shop_table {
    margin-bottom: 20px;
    width: 100%;
}

.checkout-page-style .shop_table thead tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #231f40;
    border-bottom: 1px solid #EEEEEE;
    padding: 10px 0;
    margin-bottom: 10px;
}

.checkout-page-style .shop_table tbody tr:first-child td {
    padding-top: 20px;
}

.checkout-page-style .shop_table tbody tr td {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #6f6b80;
    border: 0 none;
    padding: 5px 0;
}

a.edu-btn,
button.edu-btn {
    border-radius: 5px;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    color: #ffffff;
    background: #103675;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 400;
    transition: 0.4s;
    border: 0 none;
}

.ptb--60 {
    padding: 60px 0 !important;
}

.mb--20 {
    margin-bottom: 20px !important;
}

.checkout-page-style select {
    border: 1px solid #6F6B80;
    border-radius: 5px;
    padding: 0 25px;
    height: 60px;
    font-weight: 500;
    color: #6f6b80;
    position: relative;
    /* background: url(../images/arrow-icon.png) 95% center no-repeat transparent; */
    -webkit-appearance: none;
    appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
    opacity: 0;
    position: absolute;
}

.pl--50 {
    padding-left: 50px !important;
}

.mb--30 {
    margin-bottom: 30px !important;
}

.mb--20 {
    margin-bottom: 20px !important;
}

.mt--50 {
    margin-top: 50px !important;
}

.edu-section-gap {
    padding: 60px 0 !important;
}

a.edu-btn span {
    padding-left: 10px;
    position: relative;
    top: 7px;
}

/*** 
  
  ====================================================================
      Comment Form
  ====================================================================
  
  ***/
  
  .comment-form{
      position:relative;
      margin-top:30px;
  }
  
  .comment-form .group-title h4{
      position:relative;
      color:#06142d;
      font-weight:700;
      line-height:1.3em;
      margin-bottom:30px;
  }
  
  .comment-form .form-group{
      margin-bottom:20px;
  }
  
  .comment-form .form-group:last-child{
      margin-bottom:0px;
  }
  
  .comment-form .form-group input[type="text"],
  .comment-form .form-group input[type="password"],
  .comment-form .form-group input[type="tel"],
  .comment-form .form-group input[type="email"],
  .comment-form .form-group select{
      position:relative;
      display:block;
      width:100%;
      line-height:28px;
      padding:10px 25px;
      color:#888888;
      border:1px solid #dddddd;
      height:54px;
      font-size:14px;
      border-radius:0px;
      background:#ffffff;
      -webkit-transition:all 300ms ease;
      -ms-transition:all 300ms ease;
      -o-transition:all 300ms ease;
      -moz-transition:all 300ms ease;
      transition:all 300ms ease;
  }
  
  .comment-form .form-group input[type="text"]:focus,
  .comment-form .form-group input[type="password"]:focus,
  .comment-form .form-group input[type="tel"]:focus,
  .comment-form .form-group input[type="email"]:focus,
  .comment-form .form-group select:focus,
  .comment-form .form-group textarea:focus{
      border-color:#43b97e;
  }
  
  .comment-form .form-group .check-box label{
      position:relative;
      color:#06142d;
      font-size:15px;
      opacity:0.80;
  }
  
  .comment-form .form-group textarea{
      position:relative;
      display:block;
      width:100%;
      line-height:24px;
      padding:15px 25px 25px;
      color:#888888;
      border:1px solid #dddddd;
      height:215px;
      background:#ffffff;
      resize:none;
      font-size:14px;
      border-radius:0px;
      -webkit-transition:all 300ms ease;
      -ms-transition:all 300ms ease;
      -o-transition:all 300ms ease;
      -moz-transition:all 300ms ease;
      transition:all 300ms ease;
  }
  
  .comment-form .theme-btn{
      cursor:pointer;
  }
   
  
  .star-rating {
      border: solid 1px #ccc;
      display: flex;
      flex-direction: row-reverse;
      font-size: 1.5em;
      justify-content: center;
      padding: 0 0.2em;
      text-align: center;
      width: 5em;
    }
    
    .star-rating input {
      display:none;
    }
    
    .star-rating label {
      color: #ccc;
      cursor: pointer;
      padding: 0 16px !important;
      font-size: 30px !important;
    }
    
    .star-rating :checked ~ label {
      color:#f90;
    }
    
    .star-rating label:hover,
    .star-rating label:hover ~ label {
      color:#fc0;
    }
    
    .star-rating {
      width: 100%;
      height: 50px;
      align-items: center;
  }
  
  
  .star-rating label.star:before, .star-rating label.star:after {display: none;}
  
  .job-listing {
      background: #eff3ff;
      padding: 20px 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
  }
  .job-listing a {
      background: #103675;
      text-align: center;
      font-size: 14px;
      padding: 6px 10px;
      text-transform: uppercase;
      font-weight: normal;
      color: #fff;
  }
  
  section.job-details-page {
      padding: 50px 10px;
  }
  section.job-details-page h2 {
      margin-bottom: 20px;
    font-size: 18px;
  }
  section.job-details-page p {
    margin-bottom: 20px;
}
  section.job-details-page ul {
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 30px;
  }
  section.job-details-page ul li i {margin-right: 15px;color: #333;}
  section.job-details-page a.button {
  background: #103675;
  color: #fff;
  padding: 8px 30px;
  font-size: 14px;
  text-transform: uppercase;
  }
  section.job-details-page h1 {
  font-size: 28px;
  margin-bottom: 30px;
  }
  .modal .form-group label {
  font-size: 13px;
  margin-bottom: 15px;
  color: #333;
  }
  .modal .form-group {
  margin-bottom: 20px;
  }
  .star-rating label.star:before, .star-rating label.star:after {display: none;}
  
  .featured-topics .bg-wrapper {
      height: 100%;
  }
  .featured-topics .bg-wrapper h6 { 
      margin-top: 15px;
  }
  .featured-topics .bg-wrapper h6 img {opacity: 0.2;margin-right: 8px;}
  
  .featured-topics li span {
      line-height: 1.3;
      display: inline-block;
  }
  body div .owl-dots.disabled {
      display: flex !important;
      width: 100%;
      /* padding-top: 20px; */
      justify-content: center;
  }
  
  .owl-dots.disabled button {
      background: #d8d8d8;
      width: 10px;
      height: 10px;
      border-radius: 50px;
  }
  
  .owl-dots.disabled button.owl-dot.active {
      background: #103675;
  }
  .course-overview h5 {
      margin-bottom: 10px;
  }
  

.section.page-banner-section.bg-color-1.course_detail_banner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #103675;
    transform-origin: 100% -100%;
    -webkit-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    transform: skewX(-15deg);
    width: 70%;
    z-index: -1;
}

.single-course .courses-image a img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
}

.single-course .courses-image a {
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
}

.single-course .courses-content .top-meta .tag {
    text-align: center;
}


.course-hover .top-meta .tag {
    text-align: center;
}

.mobile-menu-login,
.lardge-dev {
    margin-right: 0;
}

.main-footer .lower-box .text {
    width: calc(100% - 150px);
}

.main-footer .lower-box .text br {
    display: none;
}

.single-review .review-author img {
    object-fit: cover;
}

.sticky-bar-comm ul li a::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #0003;
    position: absolute;
    left: 0;
    bottom: -15px;
}

.sticky-bar-sticky ul li a::after {
    background: #ffffff2e;
}

.checkout-form {
    width: 100%;
    justify-content: space-between;
    display: flex;
}

.checkout-page-style {
    width: calc(50% - 15px);
}

.cart-summary-button-group {
    display: flex;
    justify-content: flex-start;
}

.cart-summary-button-group .edu-btn,
.cart-summary-button-group .btn {
    margin-right: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0;
    height: 52px;
    line-height: 52px;
}

.cart-summary-button-group .edu-btn span,
.cart-summary-button-group .btn span {
    position: static !important;
    margin-left: 15px;
}

.cart-summary-button-group .btn {
    background: #fff1f1;
    color: #ff5d5d;
    width: 150px !important;
}

.comment-form-consent img {
    width: 110px;
}

img.paytm-logo {
    width: 70px;
}

.comment-form-consent {
    width: 180px;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    margin-left: -1px;
    position: relative;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
}


.ui-datepicker-calendar thead th {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: #78909C;
}

.ui-datepicker-calendar tbody td {
    width: 2.5rem;
    text-align: center;
    padding: 0;
}

.ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #546E7A;
    font-size: 0.875rem;
    text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
    background-color: #E0F2F1;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
    background-color: #009688;
    color: white;
}

.ui-datepicker-header a.ui-corner-all {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
    background-color: #ECEFF1;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    /* background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg=="); */
    background-repeat: no-repeat;
    background-size: 0.5rem;
    background-position: 50%;
    transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    /* background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg=='); */
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
}

.ui-datepicker-header a>span {
    display: none;
}

.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color: #78909C;
    font-weight: 400;
    font-size: 0.75rem;
}

.banner-right-content p,
.banner-right-content h2,
.banner-right-content h6 a,
.banner-right-content ul li,
.banner-right-content h6,
.banner-right-content span.time {
    color: #000 !important;
}

.submit-section button {
    justify-content: center;
}

.accordion-item.border-none {
    border: 0;
}

.accordion-collapse.collapse.show {
    border: 1px solid #f0f0f0;
    margin-top: 15px;
}

.accordion-item.border-none textarea {
    border: 1px solid #f0f0f0;
    height: 95px;
}

.single-mini-post .mini-post-content .title a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}


.like-buton {
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}

.like-buton input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 4px;
}

.like-buton input:checked+span i::before {
    content: "\f164";
}

.single-course .courses-content .title a {
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 1; */
    /* -webkit-box-orient: vertical; */
    /* max-width: 150px; */
    font-size: 12px;
}

.modal-login #modal-login .form-group {
    width: 100%;
    max-width: 350px;
    margin: 0 auto 20px auto;
}

.header-cart .cart-btn {
    width: 40px;
}

.header-login.desktop-login button {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 10px;
    padding: 0;
    background: transparent;
    color: #0f3471;
}

.lardge-dev {
    display: block !important;
    font-size: 33px;
}

span.text-hide-tab {
    text-indent: -500000px;
    display: flex;
    align-items: center;
}

span.text-hide-tab::after {
    content: "Login";
    text-indent: 0;
    display: flex;
    align-items: center;
}

.skiptranslate.goog-te-gadget span {
    display: none;
}

.lang-wrapper {
    width: 55px;
    height: 34px;
}

select.goog-te-combo {
    text-indent: 0 !important;
    background: transparent !important;
    border: 0 !important;
    position: relative;
    top: 13px;
    width: 55px;
    color: transparent !important;
    z-index: 1;
    cursor: pointer;
}

div#\:0\.targetLanguage {
    text-indent: 0;
    color: #333;
    width: 100%;
    height: 100%;
}

.goog-te-gadget {
    text-indent: 0;
    color: transparent !important;
}

div#google_element {
    background-color: transparent;
    padding-right: 0;
    border-radius: 4px;
    position: relative;
    padding-left: 0;
    padding-top: 0;
    width: 55px;
    height: 100% !important;
}

.goog-te-gadget .goog-te-combo {
    margin: 0 !important;
}

.goog-te-gadget {
    display: block !important;
}

div#google_element::before {
    position: absolute;
    content: "\f0ac";
    font-family: 'FontAwesome';
    color: #18254a;
    font-size: 27px;
    left: 0;
    top: -1px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: center;
}

select.goog-te-combo * {
    color: #18254a;
}

.goog-te-gadget .goog-te-combo:focus-visible {
    outline: 0;
}

.free-signup-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.free-signup-outer p {
    position: relative;
    color: #00184f;
    font-size: 17px;
    line-height: 1.2em;
}

.free-signup-outer .singup {
    font-weight: 400;
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    background: #b40f23;
    padding: 7px 28px;
}

.logo a img {
    width: 230px;
}

.footer-about p {
    color: #00184f;
    font-size: 15px;
    line-height: 1.2em;
}

.footer-about .btn {
    height: auto;
    line-height: initial;
    padding: 8px;
    margin-top: 15px;
}

.footer-store h6 {
    color: #00184f;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}


.loader-outer {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: 0.5s all;
}

.loaderadd {
    opacity: 0;
    z-index: 0;
    transition: 0.5s all;
}



.loader-inner {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

}

.loader-inner img {
    transform: scale(1);
    width: 50px;
    height: 50px;
    text-align: center;
    margin: 0 auto;
    -webkit-animation: bounce 5s;
    animation: bounce 5s;
}

@-webkit-keyframes bounce {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(3);
    }

}

@keyframes bounce {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(3);
    }

}

.footer-column h5 {
    font-size: 15px;
}

.misscall {
    background-color: #f1f1f1;
    padding: 10px 0;
}

.misscall-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.misscall-inner a p {
    color: #18254a;
    font-size: 13px;
    letter-spacing: 0.8px;
}

.new-newsletter {
    width: 100%;
    position: relative;
    padding: 100px 30px;
    background-color: #ededf4;
    text-align: center;
    /* background-image: url(../images/newsletter.png); */
    background-position: right 0;
    background-repeat: no-repeat;
}

.new-newsletter .subscribe-form h6 {
    position: relative;
    color: #00184f;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
}

.new-newsletter .subscribe-form p {
    text-align: left;
    font-size: 16px;
}

.new-newsletter form input {
    background-color: #fff !important;
}

.new-newsletter form {
    margin-top: 20px;
}

.main-footer .list li {
    position: relative;
    color: #00184f;
    font-size: 15px;
}

footer .address-foot li {
    padding: 5px 0;
}

.address-foot li i {
    padding-right: 5px;
    color: #b40f23;
}

.time-foot {
    padding-left: 18px;
}

.whatsapp-section {
    position: fixed;
    bottom: 70px;
    left: 30px;
    width: 45px;
    height: 45px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background-color: #4bc959;
    border: 0;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    font-size: 16px;
    color: #ffffff;
    z-index: 99;
}

.whatsapp-section i {
    font-size: 20px;
}

.course-new-list-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    border-right: 1px solid #e6e6e6;
}

.course-new-list-inner:last-child {
    border: 0;
}

.course-new-list-inner i {
    color: #18254a;
}

.course-new-list-inner p {
    font-size: 12px;
    margin: 0;
    padding-left: 10px;
}

.whatsapp-inner p {
    display: none;
}



.course-tag {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    background: #b40f23;
    padding: 5px 10px;
}

.course-tag p {
    color: #fff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.section-department {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-top: 50px;
}



.section-department2 {
    background-position: 100% 20%;
}

.section-department:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(24 37 74 / 70%);
}

.section-department .content-box {
    z-index: 1;
}

.section-department .content-box .text {
    color: #fff;
}

.section-department .content-box h2 {
    color: #fff;
}

.testimonial-slider .owl-dots {
    display: flex !important;
    width: 100%;
    padding-top: 20px;
    justify-content: center;

}

.testimonial-slider .owl-dots button {
    background: #d8d8d8;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin: 2px;
}

.testimonial-slider button.owl-dot.active {
    background: #103675;
}

.new-contact {
    /* margin-top: 37px; */
    margin-top: 68px;
}

.twitter {
    background: linear-gradient(135deg, #00d2ff, #3a7bd5);
    /* box-shadow: 0px 5px 10px rgb(0 210 255 / 50%); */
    color: white;
    border-radius: 50%;
    transition: 0.5s all;
}

.twitter i {
    background-color: transparent !important;
    transition: 0.5s all;
}

.twitter:hover {
    transform: translateY(-10px);
    transition: 0.5s all;
}

.twitter:hover i {
    transition: 0.5s all;
}

.linkedin {
    background-color: #0976b4;
    /* box-shadow: 0px 5px 10px rgb(0 210 255 / 50%); */
    color: white;
    border-radius: 50%;
    transition: 0.5s all;
}

.linkedin a {
    background-color: transparent !important;
    transition: 0.5s all;
}

.linkedin:hover {
    transform: translateY(-10px);
    transition: 0.5s all;
}

.linkedin:hover i {
    transition: 0.5s all;
}

.facebook {
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    /* box-shadow: 0px 5px 10px rgb(0 198 255 / 32%); */
    color: white;
    border-radius: 50%;
    transition: 0.5s all;
}

.facebook a {
    background-color: transparent !important;
    transition: 0.5s all;
}

.facebook:hover {
    transform: translateY(-10px);
    transition: 0.5s all;
}

.facebook:hover i {
    transition: 0.5s all;
}

.instagram {
    background: linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045);
    /* box-shadow: 0px 5px 10px rgb(0 198 255 / 32%); */
    /* box-shadow: 0px 5px 10px rgb(253 29 29 / 50%); */
    color: white;
    border-radius: 50%;
    transition: 0.5s all;
}

.instagram a {
    background: transparent !important;
    transition: 0.5s all;
}

.instagram:hover {
    transform: translateY(-10px);
    transition: 0.5s all;
}

.instagram:hover i {
    transition: 0.5s all;
}

.feature-intro {
    padding-left: 15px;
    padding-right: 15px;
}

.feature-inner h3 {
    font-size: 16px;
    color: #18254a;
}

.feature-inner p {
    font-size: 14px;
}

.feature-inner {
    margin-bottom: 30px;
}

.text-box-new:hover h3 {
    color: #b40f23;
}

.text-box-new {
    transition: 0.5s all;
}

.feature-inner .icon-box {
    margin-bottom: 10px;
}

.head-choose {
    font-size: 40px;
    text-transform: capitalize;
}

.meet-slide .owl-dots {
    display: flex !important;
    width: 100%;
    padding-top: 20px;
    justify-content: center;

}

.meet-slide .owl-dots button {
    background: #d8d8d8;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin: 2px;
}

.meet-slide button.owl-dot.active {
    background: #103675;
}

.faq-image {
    height: 280px;
}

.faq-image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.course-details-banner-content .courses-btn {
    justify-content: start;
}

.courses-meta .author-name {
    font-size: 12px;
}

.title:hover {
    color: #b40f23;
}

.title {
    transition: 0.5s all;
}

.government-space h5 {
    transition: 0.5s all;
}

.government-space h5 a:hover {
    color: #b40f23;
}

.blog-image {
    height: auto;
    /* height: 400px; */
}

.blog-image a {
    height: 100%;
}

.blog-image a img {
    height: 100%;
    object-fit: cover;
}

.sidebar-widget.sidebar-widget-about h3 {
    font-size: 25px;
}

.sidebar-widget.sidebar-widget-about .textwidget {
    margin-top: 20px;
}

.sidebar-widget.sidebar-widget-about .textwidget p {
    font-size: 13px;
    margin: 10px 0;
}

.sidebar-widget.sidebar-widget-about .social-box {
    margin-top: 20px;
}

.sidebar-widget.sidebar-widget-about .social-box {
    margin-top: 20px;
}

section.about-us-trial .heading-section {
    text-transform: capitalize;
}



.faq-block .inner-box .text p a {
    color: #18254a;
    font-weight: 600;
    display: block;
    margin: 10px 0;
}

.text-show1,
.text-show2 {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 7;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.terms-qutaion .panel .panel-body ol {
    list-style: auto;
    padding-left: 20px;
}


/* cart */

.summary-section {
    margin-top: 30px;
    position: relative;
}

.summary-section h3 {
    font-size: 25px;
    color: #333333;
}

.summary-outer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    border: 1px solid #d7d7d7;
    padding: 30px;
}

.summary-left-one {
    display: flex;
    align-items: flex-start;
}

.summary-left-img {
    width: 100px;
    height: 50px;
}

.summary-left-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.summary-left-content {
    padding: 0 40px;
}

.summary-left-two {
    margin-top: 20px;
}


.close-summary {
    font-size: 20px;
    font-weight: 100;
    color: #c1c1c1;
    cursor: pointer;
}

.summary-right {
    position: relative;
    text-align: right;
}

.summary-left-content h4 {
    color: #363636;
    font-size: 16px;
}

.summary-left-content p {
    color: #666666;
    font-size: 12px;
}

.summary-price {
    color: #18254a;
    font-size: 16px;
    margin-top: 5px;
}

.summary-cut {
    font-size: 16px;
    text-decoration: line-through;
    color: #666666;
}

.summary-cut i {
    text-decoration: line-through;
}

.summary-percent {
    font-size: 16px;
    color: #18254a;
    font-weight: 600;
}

.summary-left-two label {
    color: #363636;
    font-weight: 600;
}

.summary-left-two select {
    height: auto !important;
    width: 30%;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #f0f7ff;
    /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 4px;
    overflow: hidden !important;
    white-space: pre;
    text-overflow: ellipsis;
    border: 0;
}

.summary-left-two select:focus {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    border: 0;
}

.free-plan {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #d7d7d7;
    padding: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #ededed;
}

.free-plan-left p {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
}

.free-plan-left h3 {
    font-size: 20px;
    color: #363636;
    font-weight: 600;
}

.free-pan-delete {
    color: #18254a;
}

.recommend .accordion .panel-heading {
    background-color: #f0f7ff;
}

.recommend .accordion .panel-title a.collapsed {
    color: #18254a;
    font-weight: 500;
}

.recommend .accordion .panel-title a {
    color: #18254a;
    font-weight: 500;
}

.recommend-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.get-btn-btn {
    background-color: #18254a;
    color: #fff;
    padding: 5px 20px;
}

.price-recommend {
    color: #18254a !important;
    font-size: 13px !important;
    font-weight: 600;
}

.price-recommend span {
    color: #666666;
    font-size: 13px;
    margin-left: 7px;
    text-decoration: line-through;
}

.price-recommend span i {
    text-decoration: line-through;
}

.recommend-list ul li {
    color: #363636;
    font-size: 12px;
    margin: 0;
    list-style: circle !important;
    position: relative;
    padding-left: 20px;
}

.recommend-list {
    margin-top: 20px;
}

.recommend-list ul {
    padding: 0;
    margin: 0;
}

.recommend-list ul li:after {
    position: absolute;
    content: '\f111';
    font-family: 'FontAwesome';
    top: 2px;
    left: 0;
    font-size: 7px;
}

.recommend .qtySelector {
    border: 0;
    width: 150px;
    height: 35px;
    margin: 20px 0;
    background-color: #f0f7ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recommend .qtySelector .fa {
    padding: 10px 15px;
    height: 100%;
    float: left;
    font-size: 12px;
    cursor: pointer;
    color: #18254a;
}

.recommend .qtySelector .fa.clicked {
    font-size: 12px;
    padding: 10px 15px;
}

.recommend .qtySelector .qtyValue {
    border: none;
    padding: 5px;
    height: 100%;
    float: left;
    text-align: center;
    background-color: #f0f7ff;
}

.subtotal-section {
    padding: 30px;
    background-color: #ededed;
    margin-top: 30px;
}

.subtotal-section h3 {
    color: #363636;
    font-size: 20px;
}

.subtotal-section p {
    color: #18254a;
    font-weight: 500;
}

.subtotal-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.subtotal-price h4 {
    color: #363636;
    font-size: 16px;
}

.subtotal-price p {
    color: #103675;
    font-size: 16px;
}

.subtotal-description {
    text-align: center;
    margin: 10px 0;
}

.subtotal-description p {
    margin: 5px 0;
    color: #666666;
    font-size: 13px;
}

.subtotal-description p span {
    color: #103675;
}

.subtotal-description p i {
    color: #103675;
    padding: 0 2px;
}

.ready-btn {
    background-color: #18254a;
    color: #fff;
    padding: 5px 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0px;
}

.cart-head {
    font-size: 25px;
    color: #363636;
}

.cart-section {
    margin: 50px 0;
}

.get-btn-btn:hover {
    color: #fff;
}

.ready-btn:hover {
    color: #fff;
}

/* chechout */
.checkout-outer h3 {
    font-size: 25px;
    color: #333333;
}

.address-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.address-content h6 {
    font-size: 16px;
    color: #363636;
}

.address-content p {
    color: #666666;
    font-size: 14px;
}

.address-edit a {
    display: block;
    text-decoration: underline;
    color: #18254a;
    font-size: 14px;
    margin-top: 20px;
}

.address-edit {
    text-align: right;
}

.address-edit i {
    color: #fff;
    background-color: #32d932;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
}

.address-content {
    flex: 0 0 50%;
}

.address-section-main {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #d7d7d7;
    padding: 30px;
}

.address-form {
    margin-top: 30px;
    /* display: none; */
}

.address-form form input,
.address-form form select {
    height: 40px !important;
    line-height: 10px !important;

}

.save-btn {
    background-color: #18254a;
    color: #fff !important;
    padding: 5px 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0px;
    width: 100%;
    border: 0;
}

.cancel-btn {
    background-color: #f0f7ff;
    color: #18254a;
    padding: 5px 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0px;
    width: 100%;
    border: 0;
}

.address-form p {
    color: #666666;
    font-size: 13px;
}

.address-form form {
    margin-top: 10px;
}

.field-text {
    margin-top: 20px;
}

/* Choose all input elements that have the attribute: type="radio" and make them disappear.*/
.methods-list input[type="radio"] {
    display: none;
}

/* The label is what's left to style. 
  As long as its 'for' attribute matches the input's 'id', it will maintain the function of a radio button. */
.methods-list label {
    padding: 4px 15px !important;
    display: inline-block;
    border: 0;
    cursor: pointer;
    text-align: center;
    background-color: aliceblue;
    color: #18254a;
    font-size: 13px !important;
    text-transform: capitalize;
    font-weight: 600 !important;
}

.methods-list .blank-label {
    display: none;
}

/* The '+' is the adjacent sibling selector.
  It selects what ever element comes right after it,
  as long as it is a sibling. */
.methods-list input[type="radio"]:checked+label {
    background-color: #18254a;
    color: #ffff;
}

.methods-list input[type="radio"]:checked~label::before,
.methods-list input[type="radio"]~label::before,
.methods-list input[type="radio"]:checked~label::after,
.methods-list input[type="radio"]~label::after {
    display: none;
}

.payment-form .input-group-text {
    background: transparent;
}

.payment-form form input::placeholder {
    font-size: 13px;
}

.payment-form form input {
    height: 40px;
}

.payment-form {
    margin-top: 30px;
}

.order-btn {
    background-color: #18254a;
    color: #fff;
    padding: 5px 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    border: 0;
}

.payment-form .form-group {
    margin: 0;
}

.payment-form form label {
    font-size: 14px;
    color: #363636;
    font-weight: 500;
}

.payment-form .form-check-input {
    height: 1em !important;
    opacity: 1 !important;
}

.payment-form .form-check {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #18254a;
}

.payment-section {
    border: 1px solid #d7d7d7;
    padding: 30px;
    margin-bottom: 30px;
}

.checkout-summary {
    padding: 30px;
    background-color: #ededed;
}

.checkout-summary h3 {
    color: #363636;
    font-size: 20px;
}

.checkout-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout-item p {
    font-size: 14px;
    color: #666666;
}

.checkout-item a {
    color: #18254a;
    text-decoration: underline;
    font-size: 14px;
}

.disclamers a {
    color: #666666;
    font-size: 12px;
    text-decoration: underline;
}

.checkout-subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.checkout-subtotal h6 {
    font-size: 15px;
    color: #363636;
}

.checkout-subtotal p {
    color: #103675;
    font-size: 15px;
}

.checkout-gst {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.checkout-gst h6 {
    font-size: 15px;
    color: #363636;
}

.checkout-gst p {
    color: #103675;
    font-size: 15px;
}

.checkout-promo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.checkout-promo h6 {
    font-size: 15px;
    color: #363636;
}

.checkout-promo i {
    color: #103675;
    font-size: 15px;
}

.valid-promo {
    font-size: 14px;
    color: #363636;
}

.valid-promo i {
    color: #18254a;
}

.checkout-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout-total h6 {
    font-size: 16px;
    color: #18254a;
}

.checkout-total p {
    color: #363636;
}

.secure-checkout {
    margin: 20px 0;
    text-align: center;
}

.secure-checkout h6 {
    font-size: 18px;
    color: #18254a;
}

.secure-checkout h6 i {
    padding-right: 10px;
}

.checkout-items-list .accordion .panel-heading {
    background-color: transparent;
}

.checkout-items-list-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ededed;
    margin-bottom: 20px;
}

.checkout-items-list .accordion .panel {
    border: 1px solid #ededed;
    margin-top: 30px;
    background-color: transparent;
}

.checkout-items-list-content-left h6 {
    color: #363636;
    font-size: 16px;
}

.checkout-items-list-content-left p {
    color: #666666;
    font-size: 13px;
}


.address-form button#dropdownMenuButton {
    border: 0;
    padding: 0 15px;
    font-weight: bold;
    background: #dedede;
    height: 100%;
    color: #000;
    border-radius: 0;
    width: 100px;
    vertical-align: middle;
    line-height: 40px;
}

.dropdown-menu {
    border: 0;
    border-radius: 0;
    font-size: 14px;
    right: 0;
}

.dropdown-menu img {
    width: 50px;
    margin-right: 0px;
}

.mobile-address {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-address button img {
    width: 21px;
    margin-right: 6px;
}

.mobile-address input:focus {
    border: 1px solid #cdcdcd;
}

.mobile-address input {
    padding-left: 10px;
}

.mobile-address-check p {
    font-size: 13px;
    color: #666666;
    margin: 10px 0;
}

.mobile-address-check .form-check-input[type=checkbox] {
    opacity: 1;
    position: static;
    height: 1rem !important;
    margin-right: 10px;
}

.mobile-address-check .form-check {
    display: flex;
    align-items: center;
}

.mobile-address input {
    border: 1px solid #cdcdcd;
}

.address-form .form-group {
    margin: 0;
}

.methods-list label img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.payment-form .input-group-text {
    width: 50px;
    height: 40px;
}

.single-course .courses-meta {
    display: block !important;
    position: relative;
}

.course-new-list-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.add-btn .add-cart-btn {
    display: block;
    background: #18254a;
    text-align: center;
    color: #fff;
    font-size: 16px;
    height: 100%;
    line-height: 45px;
}

.add-btn {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
}

.single-course:hover .add-btn {
    opacity: 1;
    transition: 0.5s all;
}

.single-course:hover .course-new-list-outer {
    opacity: 0;
    transition: 0.5s all;
}

.add-btn .add-cart-btn i {
    margin-right: 5px;
}

.course-name-tag {
    color: #18254a;
    font-size: 12px;
    display: block;
    font-weight: 600;
}

.cart-empty {
    height: 100vh;
}

.cart-empty-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 0;
    border: 1px solid #d7d7d7;
}

.cart-empty-inner h5 {
    color: #18254a;
    font-size: 30px;
}

.cont_shop {
    display: inline-block;
    background: #18254a;
    padding: 10px 15px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.cont_shop:hover {
    color: #fff !important;
}

.summary-left {
    flex: 1 0 60%;
}

.method-list1 {
    display: flex;
    align-items: center;
}

.comment-form-consent {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 0 0;
    margin-left: 0;
    position: relative;
    border-top: 0;
    border-bottom: 0;
}

.job-listing h4 {
    display: -webkit-box;   
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.header {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}


.merchandise-section {
    position: relative;
}

.merchandise-image {
    position: relative;
    height: 230px;
    overflow: hidden;
}

.merchandise-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 1s all;
}

.merchandise-image:hover img {
    transform: scale(1.1);
    transition: 1s all;
}

.merchandise-tag {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgb(242 242 242 / 72%);
    padding: 5px;
    margin: 5px;
}

.merchandise-tag p {
    font-size: 10px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.merchandise-tag p a:hover {
    color: #000;
}

.merchandise-content {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.merchandise-content p {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

p.merch-price {
    font-size: 14px;
}

.merchandise-content p a:hover {
    color: #b40f23 !important;
}

.merchandise-slide .owl-nav .owl-prev {
    display: none;
}

.merchandise-slide .owl-nav {
    position: absolute;
    top: 30%;
    background: red;
    width: 100%;
}

.merchandise-slide .owl-nav .owl-next {
    position: absolute;
    right: -20px;
}

.merchandise-slide .owl-nav .owl-next i {
    font-size: 30px;
    background: rgb(242 242 242 / 72%);
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    width: 50px;
    height: 48px;
    display: block;
    line-height: 44px;
    text-align: center;
    color: #103675;
}

.merchandise-section .section-title {
    margin-bottom: 30px;
}

.mobile-checkout {
    height: 40px;
}

.merchandise-image a {
    width: 100%;
    height: 100%;
}

.merchandise-desc {
    font-size: 12px !important;
    color: #000 !important;
}

.merchandise-grid {
    margin-top: 50px;
}

.merchandise-grid .merchandise-outer {
    margin-bottom: 20px;
}

.merchandise-all {
    margin: 20px 0 50px 0;
}

.merchandise-btn {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -41%);
    width: 100%;
    padding: 10px;
    opacity: 0;
}

.merchandise-btn a {
    display: block;
    text-align: center;
    background: #103675;
    color: #fff;
    padding: 7px 5px;
}

.merchandise-outer:hover .merchandise-btn {
    opacity: 1;
    transition: 1s all;
}

.merchandise-outer {
    position: relative;
}

.merchandise-image a:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 50%);
    width: 100%;
    height: 100%;
}

.mrchandise-pagination .pagination li a {
    border: 1px solid #f3f3f3 !important;
    margin: 0 5px;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 !important;
    color: #103675 !important;
    font-size: 17px;
    border: 0 !important;
}

.mrchandise-pagination .pagination li.active a {
    background: #103675 !important;
    color: #fff !important;
}

.mrchandise-pagination {
    margin-top: 20px;
}

.merchandise-details {
    margin-top: 150px;
    margin-bottom: 50px;
}

.merchandise-thumbnail .carousel-indicators {
    margin-left: 0 !important;
}


/* Make the image fully responsive */
.merchandise-thumbnail .carousel-image {
    height: 400px;
}

.merchandise-thumbnail .carousel-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.merchandise-thumbnail .carousel-indicators {
    display: inline-block;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: static;
    direction: rtl;
}

@media screen and (max-width:992px) {
    .merchandise-thumbnail .carousel-indicators {
        display: inline-flex;
        direction: inherit;
        height: auto;
        width: 100%;
        max-width: 800px;
        overflow-x: auto;
        position: relative;
        margin-left: 0;
        margin-right: 0;
        overflow-y: hidden;
        justify-content: normal;
        margin-bottom: 20px;
    }

    .merchandise-thumbnail .carousel-inner {
        margin-left: 0 !important;
    }

    .merchandise-thumbnail .carousel-inner img {
        width: 100%;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.merchandise-thumbnail .item {
    margin-bottom: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.merchandise-thumbnail .item.active img {
    border: 1px solid #103675;
    opacity: 1;
}

.merchandise-thumbnail .item img {
    border: 1px solid transparent;
    opacity: 0.5;
    transition: 0.5s;
}

.merchandise-thumbnail .item:hover img {
    border: 1px solid #103675;
    opacity: 1;
}

.merchandise-thumbnail .content {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Black background with transparency */
    color: #f1f1f1;
    width: 100%;
    padding: 10px;
}

.merchandise-thumbnail .content p {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    .merchandise-thumbnail .content {
        position: static;
    }
}


@media screen and (min-width: 880px) and (max-width: 1199px) {

    .merchandise-thumbnail .content {
        position: static;
    }
}

@media screen and (min-width: 576px) and (max-width: 879px) {

    .merchandise-thumbnail .content {
        position: static;
    }
}



.merchandise-thumbnail .vert .carousel-item-next.carousel-item-left,
.merchandise-thumbnail .vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.merchandise-thumbnail .vert .carousel-item-next,
.merchandise-thumbnail .vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.merchandise-thumbnail .vert .carousel-item-prev,
.merchandise-thumbnail .vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

.merchandise-section.merchandise-section-home {
    margin-bottom: 0;
    padding: 50px 0;
}

.merch-head h4 {
    font-size: 30px;
    color: #103675;
    margin: 0;
}

.merch-rat i {
    color: orange;
}

.merchandise-details-content hr {
    margin: 7px 0;
}

.mech-price h6 {
    font-size: 20px;
    color: #000;
}

.mech-price p {
    color: #000;
    font-size: 16px;
}

.merch-offer-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.merch-offer-inner {
    border: 1px solid #36363654;
    padding: 5px;
    margin: 0 5px;
    border-radius: 10px;
}

.merch-offer {
    position: relative;
}

.merch-offer-inner h6 {
    color: #000;
    font-size: 15px;
}

.merch-offer-inner p {
    font-size: 12px;
    line-height: 1.5;
    color: #000;
}

.merch-offer-inner:first-child,
.merch-offer-inner:last-child {
    margin: 0;
}

.merch-rule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.merch-rule-inner {
    text-align: center;
    padding: 5px;
}

.merch-rule-inner p {
    font-size: 15px;
    margin: 10px 0 0 0;
    color: #103675;
    font-weight: 600;
    line-height: 1.2;
}

.merch-specification ul li {
    display: flex;
    align-items: center;
}

.merch-specification ul li p {
    color: #363636;
    font-size: 16px;
    text-transform: capitalize;
}

.merch-specification ul li h6 {
    color: #000;
    font-size: 16px;
    width: 200px;
    text-transform: capitalize;
}

.merch-offer-head {
    display: flex;
    align-items: center;
}

.merch-offer-head h6 {
    margin-left: 10px;
    color: #000;
    font-size: 20px;
}

.merch-sidebar {
    border: 1px solid #36363685;
    border-radius: 10px;
    padding: 10px;
}

.merch-free-delivery p {
    color: #000;
    font-size: 15px;
    line-height: 1.3;
    margin: 0 0 10px 0;
}

.merch-free-delivery p a {
    color: #103675;
}

.merch-free-delivery p span {
    font-weight: 600;
}

.merch-location a p {
    color: #103675;
}

.merch-seller h6 {
    font-size: 14px;
    line-height: 1.3;
}

.merch-seller h6 a {
    color: #103675;
}

.merch-quant form .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.merch-quant form .form-group select {
    width: auto;
    padding: 3px 30px 3px 10px;
    background-position-y: 0px;
    margin-left: 20px;
}

.merch-quant form .form-group label {
    margin: 0;
}

.merch-quant {
    margin: 10px 0;
}

.merch-order p {
    color: #000;
    font-size: 14px;
    line-height: 1.3;
}

.merch-order p a {
    color: #103675;
}

.merch-order .merch-add {
    display: block;
    text-align: center;
    background: #103675;
    border-radius: 5px;
    color: #fff;
    padding: 5px;
    margin: 10px 0;
}

.merch-order .merch-buy {
    display: block;
    text-align: center;
    background: #fff;
    border: 1px solid #103675;
    border-radius: 5px;
    color: #103675;
    padding: 5px;
    margin: 10px 0;
}

.merch-whichlist a {
    font-size: 14px;
    color: #103675;
}

.merch-whichlist form input {
    opacity: 1;
}

.merch-wish {
    display: block;
    text-align: center;
    background: #103675;
    border-radius: 5px;
    color: #fff !important;
    padding: 5px;
    margin: 10px 0;
}

.new-footer {
    background: #405189;
    padding: 50px 0 20px 0;
}

.new-footer-logo-one img {
    width: 160px;
    margin-bottom: 10px;
}

.new-footer-logo-one p {
    color: #fff;
    font-size: 15px;
    line-height: 1.3;
}

.new-footer-logo-one a img {
    width: 139px;
    margin: 10px 2px;
}

.new-footer-logo-two h6 {
    color: #fff;
    font-size: 17px;
}

.new-footer-logo-two p {
    color: #fff;
    font-size: 15px;
    line-height: 1.3;
}

.new-footer-logo-two a p {
    margin: 10px 0;
    color: #fff;
    font-weight: 600;
}


.new-footer-link h6 {
    color: #fff;
    padding-bottom: 10px; 
    font-size: 18px;
}

.new-footer-link ul li a {
    color: #fff;
    font-size: 15px;
    margin: 0;
    padding: 1px 0;
    transition: 0.5s all;
}

.new-footer-link ul li {
    line-height: 1.5;
}

.new-footer-news .subscribe-form {
    width: auto;
}


.new-footer-copyright a p {
    color: #fff;
    font-size: 14px;
    margin: 0;
}

.new-footer-iews-outer {
    margin-top: 20px;
}

.new-footer-link ul li a img {
    width: 20px;
    margin-right: 10px;

}

.app-section {
    margin: 50px 0;
}

.app-section-content h5 {
    font-size: 30px;
    color: #000;
    line-height: 1;
    margin-bottom: 20px;
}

.app-section-content p {
    color: #000;
    font-size: 16px;
    line-height: 1.4;
}

.app-section-image {
    height: 400px;
    overflow: hidden;
}

.app-section-image img {
    height: 100%;
}

.app-section-list {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.app-section-list ul li {
    position: relative;
    padding-left: 23px;
    font-size: 15px;
}

.app-section-list ul li:after {
    position: absolute;
    content: '\f00c';
    font-family: 'FontAwesome';
    font-size: 8px;
    color: #103675;
    border-radius: 50%;
    top: 6px;
    left: 0;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border: 1px solid #103675;
}

.counter-section-home {
    padding: 20px 0;
    background: #e6e6e6ad;
}

.counter-section-content {
    border-left: 3px solid #103675;
    padding: 20px;
}

.counter-section-content p {
    color: #000;
    font-size: 18px;
}

.counter-section-counts-outer h6 {
    font-size: 30px;
    color: #103675;
}

.counter-section-counts-outer p {
    color: #000;
    font-weight: 500;
}

.counter-section-counts-outer {
    padding: 20px;
    text-align: center;
}

.featured-section {
    padding: 33px 0;
    background: rgb(64, 81, 137);
}

.featured-section .section-title {
    margin-bottom: 36px;
    text-align: center;
}

.featured-inner {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 20px;
}

.new-slider-section {
    margin: 50px 0;
}

.new-slider-section .section-title {
    margin-bottom: 15px;
    text-align: center;
}

.testimonial-wrapper {
    background: #f2f2f2;
    padding: 50px 0;
}

.testimonial-outer {
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}



.new-slider-inner img {
    min-height: 382px;
    max-height: 500px;
    margin-bottom: 50px;
    margin-top: 50px;
    margin-right: 40px;
    max-width: 576px;
    object-fit: fill;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 10px;
}

.team-img {
    height: 400px;
}

.team-img img {
    height: 100%;
    object-fit: cover;
    object-position: top;
}

img.desktop-mobile {
    display: none;
}

.mobile-slider .owl-dots.disabled {
    display: none !important;
}

.new-footer-logo-two.new-footer-logo-two-mobile {
    display: none;
}

.widget-search-mobile {
    display: none;
}

.top-department {
    padding-top: 0;
}

.featured-topics {
    background: #f2f2f2;
}

.featured-topics .bg-wrapper {
    background: #fff;
    border-radius: 10px;
}

.signup-step-container table {
    width: 100%;
    margin: 50px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid transparent;
    border-radius: 10px;
}

.signup-step-container table td {
    padding: 10px 8px;
    text-align: center;
    color: #000;
}

.signup-step-container table td:first-child {
    width: 70px;
}

.signup-step-container table th {
    text-align: center;
    padding: 10px 8px;
}

.feature-title {
    text-align: left !important;
    color: #103675;
    font-size: 20px;
}

.signup-step-container table thead {
    background-color: #103675;
    color: #fff;
}

.signup-step-container table td i.fa-check {
    color: green;
}

.signup-step-container table td i.fa-minus {
    color: #b40f23;
}

.signup-step-container table thead tr th:first-child {
    border: 1px solid transparent;
    border-radius: 10px 0 0 0px;
}

.signup-step-container table thead tr th:last-child {
    border-radius: 0 10px 0 0;
    border: 1px solid transparent;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 65px;
    z-index: 1;
}

.wizard-inner ul {
    justify-content: space-around;
    border: 0;
}

.wizard-inner ul li {
    background-color: #103675;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    z-index: 1
}

.signup-step-container {
    margin: 50px 0;
}

.wizard-inner ul li a {
    position: relative;
    display: block;
}

.wizard-inner ul li a:hover {
    color: #fff !important;
}

.wizard-inner ul li a i {
    position: absolute;
    top: -15px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    color: #000;
}

.wizard {
    margin-top: 50px;
}

.signup-step-container .title {
    text-align: center;
}

.instructor-about {
    background: #f2f2f2;
}

.instructor-about .feature-inner {
    background: #fff;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4;
    padding: 16px;
    border-radius: 10px;
}

.quality-section .nav li a {
    width: 100%;
    padding: 15px 10px;
    margin: 5px 0;
    border: 1px solid #0b3860;
    border-left: 10px solid #0b3860;
    color: #0b3860;
    font-weight: 500;
    font-size: 17px;
    width: 100%;
    border-radius: 10px;
}

.quality-section .nav {
    border: 0;
    display: block;
}

.quality-section .nav li a.active {
    border: 1px solid #b40f23;
    border-left: 10px solid #b40f23;
    color: #b40f23;
    font-weight: 600;

}

.about-us-trial .feature-intro {
    padding: 0;
}

.quality-section .tab-pane h3 {
    color: #b40f23;
    font-size: 30px;
}

.quality-section .tab-pane p {
    color: #000;
}

.quality-section .tab-pane {
    padding: 10px;
}

.quality-section {
    background: #f2f2f2;
    padding: 50px 0;
}


section.about-us-trial .title {
    text-align: center;
}


.counter-section-inst .counter-section-counts-outer {
    text-align: center;
}

.teaching-section .section-title {
    text-align: center;
    margin-bottom: 50px;
}

.teaching-section {
    background: #f2f2f2;
    padding: 50px 0;
}

.teaching-section-inner {
    padding: 20px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.teaching-section-inner h3 {
    font-size: 18px;
    color: #103675;
    margin-bottom: 10px;
}

.teaching-section-inner p {
    color: #000;
    font-size: 14px;
}

.app-section-inst {
    background-color: transparent;
}

.become-section {
    background: #f2f2f2;
    padding: 50px 0;
    background: #f2f2f2;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
}


.become-section .section-title {
    text-align: center;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}

.become-inner .become-image {
    height: 50px;
    margin-bottom: 20px;
}

.become-inner .become-image img {
    height: 100%;
    object-fit: cover;
}

.become-inner {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    height: 170px;
    width: 160px;
    margin: 0 auto 30px auto;
}

.become-content h5 {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    height: 55px;
}

.become-inner-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.testimonial-slider .owl-item {
    display: flex;
}

.testimonial-slider .owl-item .item {
    flex: 1 1 100%;
}

.faq-tabs {
    margin: 50px 0;
}

.faq-tabs .section-title {
    text-align: center;
    margin-bottom: 50px;
}

.faq-tabs ul {
    justify-content: center;
    border: 0;
}

.become-section-outer {
    border-radius: 10px;
    padding: 50px 0;
}


.c1 {
    background-color: #2980b9;
}

.c2 {
    background: #33aa58;
}

.c3 {
    background: #eab830;
}

.c4 {
    background: #48a7d4;
}

.c5 {
    background: #eab830;
}

.c6 {
    background: #d3b5d0;
}

.c7 {
    background: #6ad59a;
}

.c8 {
    background: #b4b4b4;
}

.c9 {
    background: #48a7d4;
}

.c10 {
    background: #eab830;
}

.c11 {
    background: #d3b5d0;
}

.c12 {
    background: #6ad59a;
}

.testimonial-slider .owl-stage {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.testimonial-slider .owl-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
    flex: 1;
    height: auto !important;
}

.left-space h2 {
    font-size: 30px;
    color: #444;
    font-weight: 600;
}

.panel-body ul,
.panel-body ol {
    list-style: disc;
}

.info-text-wrapper ul,
.info-text-wrapper ol,
.instructor-profile ul,
.instructor-profile ol {
    list-style: auto;
    padding: revert;
}

.mobile_error {
    padding-left: 25px;
}

section.about-us-trial ul,
section.about-us-trial ol {
    list-style: auto;
    padding: revert;
}

.gift_image {
    width: 80px;
    height: 80px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
}

.gift_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wrapper-brochures ul {
    list-style: none !important;
}

.uni-wrapper a {
    padding: 0 !important;
}

p.course_department {
    font-size: 12px;
    color: #838383;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.section.page-banner-section.bg-color-1.course_detail_banner h2 {
    color: #fff;
}

.section.page-banner-section.bg-color-1.course_detail_banner {
    color: #fff;
}

.section.page-banner-section.bg-color-1.course_detail_banner p {
    color: #fff;
}


.job-detail-banner {
    padding: 70px 0;
    background: aliceblue;
    margin-top: 60px;
}

.job-detail-content h1 {
    color: #000;
    font-size: 30px;
    margin-bottom: 15px;
}

.job-detail-content .job-detail-list {
    display: flex;
    align-items: center;
}

.job-detail-content .job-detail-list li {
    padding-left: 30px;
    color: #6a6262;
}

.job-detail-content .job-detail-list li:nth-child(1) {
    padding-left: 0;
}

.job-detail-content .job-detail-list li i {
    padding-right: 5px;
}

.job-detail-apply .button {
    background: #103675;
    color: #fff;
    padding: 8px 30px;
    font-size: 14px;
    text-transform: uppercase;
}

.job-detail-apply {
    text-align: right;
}
.job-detail-apply p {
    color: #000;
    font-size: 15px;
    margin-bottom: 10px;
}

.job-detail-apply p span {
    color: #dd4040;
}

section.job-desciption .job-details-page h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #000;
}

section.job-desciption .job-details-page p {
    color: #504c4c;
    margin-bottom: 20px;
}

section.job-desciption .job-details-page ul {
    list-style: auto;
    padding: revert;
}

section.job-desciption .job-details-page ul li {
    color: #504c4c;
    margin-bottom: 10px;
}

section.job-desciption {
    margin: 50px 0;
}

.job-side-detail {
    background: aliceblue;
    padding: 50px;
    border-radius: 15px;
}

.job-side-detail p {
    color: #000;
    font-size: 20px;
}

.job-side-detail ul li {
    display: flex;
    margin: 20px 0;
    align-items: flex-start;
}

.job-side-detail ul li p {
    color: #6e6868;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.job-side-detail ul li h6 {
    font-size: 16px;
    color: #000;
}

.job-side-detail ul li i {
    color: #103675;
    width: 40px;
    font-size: 20px;
}

.featured-head {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 600;
}

.founder {
    position: relative;
padding: 100px 0 50px 0;
background: #f2f2f2;
}

.founder-image {
    text-align: center;
    height: 400px;
    position: relative;
    z-index: 1;
}

.founder-image:after {
    position: absolute;
    content: '';
    background: #103675;
    width: 28%;
    height: 70%;
    top: -50px;
    left: 30px;
    z-index: -1;
}


.founder-image img {
    object-fit: cover;
    height: 100%;
}

.founder-content-inner {
    position: relative;
    top: -100px;
}
.founder-social i {
    font-size: 20px;
    padding-right: 10px;
    color: #000;
    cursor: pointer;
}

.founder-content h4 {
    color: #103675;
    font-size: 30px;
    margin: 0px 0 20px 0;
}

.founder-content p {
    font-size: 15px;
}

.founder-social i img {
    /* margin-right: 10px; */
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.founder-social {
    margin: 20px 0;
}

.founder-content {
    margin-top: 50px;
}
.founder .head-choose {
    margin-bottom: 50px;
}

.new-footer-link ul li a:hover {
    color: #b40f23;
    font-weight: 600;
}

.founder-content h6 {
    margin: 20px 0 0 0;
    color: #103675;
    font-size: 16px;
}

p.designation-founder {
    font-size: 11px;
}

.agreement[type="checkbox"]~label::before, .whatsapp_agree[type="checkbox"]~label::before {
    left: 7px;
}

.agreement[type="checkbox"]~label::after, .whatsapp_agree[type="checkbox"]~label::after {
    left: 10px;
    font-size: 5px;
    top: 7px;
    width: 12px;
}

.agreement[type="checkbox"], .whatsapp_agree[type="checkbox"] {
z-index: 1;
}
.agreement[type="checkbox"]~label, .whatsapp_agree[type="checkbox"]~label {
    padding-left: 32px;
    font-size: 13px;
    line-height: 1.5;
}
.agreement[type="checkbox"]~label a, .whatsapp_agree[type="checkbox"]~label a {
    color: blue;
}
.agreement[type="checkbox"] {
    left: 50px;
    width: 20px;
    height: 20px!important;
    border-radius: 50%;
    padding: 0;
}
.whatsapp_agree[type="checkbox"] {
    left: 50px;
    width: 20px;
    height: 20px!important;
    border-radius: 50%;
    padding: 0;
}
.whatsapp_icon {
    color: #1bd741;
}

.course-details-wrapper .course-learn-list ul li:after {
    position: absolute;
    content: '\f00c';
    font-weight: 900;
    font-size: 13px;
    font-family: 'FontAwesome';
    left: 10px;
    top: 3px;
    color: #103675;
}

.share-certficate ul li:after {
    display: none;
}
.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome and Opera */
}

section.course_single_video .course-tag p {
    margin: 0;
}

section.course_single_video .add-btn .add-cart-btn {
    margin: 0;
}

section.course_single_video button.btn.btn-primary.video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 transparent!important;
    border: 0!important;
    text-align: center!important;
    z-index: 1;
}

section.course_single_video button.btn.btn-primary.video-btn i {
    margin: 0;
}

#video_coursemodal1 .close {
    top: -5px;
    right: 0;
    opacity: 1;
}

.section.page-banner-section.bg-color-1.course_detail_banner h1 {
    color: #fff;
}

.recruiters-profile h3 {
    font-size: 18px;
}

.passionate {
    font-size: 18px;
    margin-bottom: 14px!important;
}
section.about-us-trial h3.heading-section {
    font-size: 18px;
    margin-bottom: 10px;
}

/* .course-details-banner-content .title {
    color: #fff;
} */

.course-details-banner-content .title:hover {
    color: #b40f23!important;
}


.accordion-button {
    background-color: #f2f2f2;
}

.accordion-button:not(.collapsed) {
    background-color: #f2f2f2;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-collapse.collapse.show  {
    margin-top: 0;
}

.accordion-button {
    font-size: 1rem;
    color: #333;
    font-weight: 600;
}

